// PointsHome.js
import React, { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { httpsCallable } from 'firebase/functions'
import { Timestamp } from 'firebase/firestore'
import Amplitude from 'amplitude-js'

import { functions } from '../../App'
import { useWallet } from '../../contexts/WalletContext'
import Header from '../headers/light.js'
import WhitelistConfirmationModal from './WhitelistConfirmationModal'
import fortunes from './Fortunes'

import { Divider } from 'components/misc/Divider'
import Billboard from './Billboard'
import PointsCampaign from './PointsCampaign'
import Leaderboard from './Leaderboard'
import YourPoints from './YourPoints'
import YourReferralCode from './YourReferralCode'
import TodaysFortune from './TodaysFortune'

import GhostLogo from 'components/misc/GhostLogo'
import Accordions from 'components/misc/Accordions'
import { InvestorsWidget } from 'components/misc/InvestorsWidget'
import Tooltip from 'components/misc/Tooltip'
import { ReactComponent as AjnaIcon } from 'assets/partnerLogos/ajna.svg'

const epoch1accordions = [
  {
    headerLeft: 'Get whitelisted',
    headerRight: '420 points',
    body: 'When you complete the whitelisting activities, you earn 420 points total. This is a one-time bonus for joining the Truly community. For you to be eligible for this bonus, you must be following Truly on Twitter and Telegram at the time of the snapshot.',
  },
  {
    headerLeft: 'Share referral code on Twitter',
    headerRight: '500 points',
    body: 'Share your referral code on Twitter to earn 500 points. This is a one-time bonus. Your tweet must still be present at the time of the snapshot.',
  },
  {
    headerLeft: 'Invite people to Truly',
    headerRight: '750 + 10% points',
    body: "For every person you refer to Truly, you'll receive a fixed 750 point reward and you'll collect 10% of your referral's points without reducing the amount they earn. (Your 10% bonus excludes participation in the 10% your referral earns from their referrals.) This is a great way to earn points passively.",
  },
  {
    headerLeft: 'Share the daily fortune on Twitter',
    headerRight: '100+ points',
    body: "Every day, a new fortune will appear on the points page. Share the fortune on Twitter to earn 100 points. You'll earn 1,000 points if you share for 7 days in a row.",
  },
  {
    headerLeft: 'Participate in contests',
    headerRight: '100 - 10k points',
    body: 'Truly will host Twitter contests for the best community posts. Pay attention to the @TrulyHQ Twitter and Telegram for the latest information on upcoming contests and results.',
  },
]

const epoch2accordions = [
  {
    headerLeft: 'Buy USH from Truly',
    headerRight: '2 points / USH',
    body: "For every USH token you buy from Truly, you'll immediately earn 1 point. There's no limit to the number of points you can earn this way. The more you buy, the more you earn.",
  },
  {
    headerLeft: 'Hold USH',
    headerRight: '1 point / USH / day',
    body: "Each day your wallet holds Truly, you'll earn 1 point per token. This is a great way to earn points passively.",
  },
  {
    headerLeft: 'Leverage USH on',
    logo: <AjnaIcon />,
    headerRight: 'TBA',
    body: 'Stay tuned for more details.',
  },
  // {
  //   headerLeft: 'Leverage USH on',
  //   logo: <EulerIcon />,
  //   headerRight: 'TBA',
  //   body: 'Stay tuned for more details.',
  // },
]

const OuterContainer = tw.div`relative mx-0 md:-mx-12` // Ghost Logo is positioned relative to this div
const Container = tw.div`max-w-screen-xl mx-auto pt-16 flex flex-col md:flex-row gap-8`

const Column = styled.div`
  ${tw`flex flex-col z-10 mx-0 md:mx-4 w-full`}
  ${({ width }) =>
    width &&
    css`
      @media (min-width: 768px) {
        width: ${width};
      }
    `}

  // Default order for mobile and then change for larger screens
  order: ${({ order }) => order}; // Add this line to accept 'order' as a prop

  @media (min-width: 768px) {
    // Reset order for desktop
    order: 0;
  }
`
const Section = tw.div``
const Title = tw.p`text-2xl md:text-3xl font-sans text-white font-light text-left`
const Subtitle = styled.p`
  ${tw`text-2xs md:text-sm font-sans text-white font-normal md:font-light text-left`}
`
const Label = tw.p`text-2xs md:text-xs text-gray-500 font-sans uppercase font-bold tracking-widest`

const Link = tw.a`transition-all duration-300 font-bold text-turquoise hover:underline`
const Content = tw.div``
const FixedContent = tw.div`sticky top-0 bg-[#1B1B1B] p-4 md:p-8 border-[1px] border-gray-500 rounded-lg`

const TipText = tw.p`text-2xs md:text-sm font-sans text-gray-500 font-medium text-left mt-1`

const dividerCustomStyle = tw`w-full mx-0 my-8 border-gray-500`
const smallDividerCustomStyle = tw`w-full mx-0 my-8 border-gray-700`

const YourBoost = tw.p`text-xs font-sans font-semibold text-white text-center mt-4`

const BoldText = tw.span`font-bold`

export const PointsHome = () => {
  const containerRef = useRef(null)
  const { walletAddress, userProgress } = useWallet()
  const [isLoading, setIsLoading] = useState(true)
  const [leaderboardData, setLeaderboardData] = useState([])
  const [rank, setRank] = useState(null)
  const [seasonDetails, setSeasonDetails] = useState([])
  const [fortuneIndex, setFortuneIndex] = useState(null)
  const [twitterData, setTwitterData] = useState(null)

  // Only show modal if the user joined the points program less than 20 seconds ago
  const [showWelcomeModal, setShowWelcomeModal] = useState(() => {
    const joinedTimestamp = userProgress?.joinedPointsProgram
      ? new Date(userProgress.joinedPointsProgram.seconds * 1000)
      : null
    const now = Date.now() // current time in milliseconds

    if (joinedTimestamp) {
      const timeDiff = now - joinedTimestamp.getTime() // difference in milliseconds
      const secondsAgo = timeDiff / 1000 // convert to seconds
      return secondsAgo < 20
    }

    return false // default state if no timestamp is available
  })

  const getSeasonDetails = httpsCallable(functions, 'getSeasonDetails')

  useEffect(() => {
    // Scroll to top of the page when the component mounts
    window.scrollTo(0, 0)

    getSeasonDetails({ number: 2 }) // Modify this based on active season
      .then((result) => {
        if (result.data.success) {
          const { data } = result.data
          setSeasonDetails(data)
          setFortuneIndex(data.fortuneIndex)
        } else {
          console.error('Season details fetch failed:', result.data.message)
        }
      })
      .catch((error) => {
        console.error('Error calling getSeasonDetails function:', error)
      })
  }, []) // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const getTwitterDataByWalletAddress = httpsCallable(functions, 'getTwitterDataByWalletAddress')

    getTwitterDataByWalletAddress({ walletAddress: walletAddress })
      .then((result) => {
        setTwitterData(result.data)
        console.log('set Twitter Data', result.data)
      })
      .catch((err) => {
        console.error('Error fetching Twitter data:', err)
      })
  }, [userProgress])

  useEffect(() => {
    setIsLoading(true)
    const fetchLeaderboardAndRank = async () => {
      const fetchLeaderboard = httpsCallable(functions, 'getLeaderboard')
      const getUserRank = httpsCallable(functions, 'getUserRank')

      try {
        const leaderboardResponse = await fetchLeaderboard({ limit: 10 })
        const rankResponse = await getUserRank({ walletAddress })

        setLeaderboardData(leaderboardResponse.data.leaderboard)
        setRank(rankResponse.data.rank)
      } catch (error) {
        console.error('Error fetching leaderboard or rank:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchLeaderboardAndRank()
  }, [walletAddress])

  // Checks to see if the user joined in the first season (Before June 1, 2024)
  const firstMover =
    userProgress?.joinedPointsProgram?._seconds !== undefined &&
    new Date(userProgress.joinedPointsProgram._seconds * 1000) < new Date('2024-06-01')

  return (
    <>
      <Header roundedHeaderButton={true} mode={'dark'} hideBorder={false} />
      <OuterContainer>
        <Billboard
          text={
            <>
              <BoldText>Earning points is simple.</BoldText> Spread the word about non-correlated RWA returns. We want
              to get our token in the hands of our <BoldText>real users.</BoldText>
            </>
          }
        />
        <Container ref={containerRef}>
          {userProgress ? (
            <>
              <Column width="60%" order={2}>
                <Section>
                  <Title>Points campaign</Title>
                  <Subtitle>
                    Get rewarded for helping grow crypto's first permissionless RWA protocol.{' '}
                    <Link href={'https://docs.trulybased.com'} target={'_blank'}>
                      Learn more.
                    </Link>
                  </Subtitle>
                  <PointsCampaign
                    epoch={1} // Hardcoded for now
                    endDate={seasonDetails.endDate}
                    totalSeasonPoints={seasonDetails.totalPoints}
                    name={seasonDetails.name}
                    isLoading={isLoading}
                  />
                </Section>

                <Divider customStyle={dividerCustomStyle} />

                <Section>
                  <Title>Leaderboard</Title>
                  <Leaderboard leaderboardData={leaderboardData} isLoading={isLoading} />
                </Section>

                <Divider customStyle={dividerCustomStyle} />

                <Section>
                  <Title>How to earn points</Title>

                  <Content style={{ marginTop: '1.25rem' }}>
                    <Label>Epoch #1</Label>
                    <Accordions containerStyle={tw`mt-4 mb-8`} accordions={epoch1accordions} />

                    <Label>Epoch #2</Label>
                    <Subtitle style={{ margin: '6px 0 0 0' }}>
                      Only the first $10M in TVL will be allowed to participate in Epoch #2.
                    </Subtitle>
                    <Accordions containerStyle={tw`mt-4 mb-8`} accordions={epoch2accordions} />
                  </Content>
                </Section>

                {/* This only renders on mobile */}
                <InvestorsWidget containerStyle={tw`mt-0 mb-8 flex md:hidden`} />
              </Column>

              <Column width="40%" order={1}>
                <FixedContent>
                  <Section>
                    <Title>Your points</Title>
                    <TipText>Points track your contributions to the Truly ecosystem.</TipText>
                    <YourPoints rank={rank} userProgress={userProgress} />
                    {firstMover && (
                      <YourBoost>
                        <BoldText>🔥First Mover Boost:</BoldText> 100% more points from your referrals' activities.
                      </YourBoost>
                    )}
                  </Section>

                  <Divider customStyle={smallDividerCustomStyle} />

                  <Section>
                    <Title>Your referral code</Title>
                    <TipText>Earn 750 points for each referral and a bonus 10% of their points earned.</TipText>

                    <YourReferralCode
                      referralCode={userProgress?.referralCode}
                      isReferralCodeSharedToTwitter={userProgress?.isReferralCodeSharedToTwitter}
                      twitterData={twitterData}
                    />
                  </Section>

                  <Divider customStyle={smallDividerCustomStyle} />

                  <Section>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItem: 'center' }}>
                      <Title>Today's fortune</Title>
                      <Tooltip custom={{ marginTop: '.5rem' }}>
                        <span>
                          Earn points by helping spread the word about Truly. Earn 100 points each day you share. Share
                          7 days in a row and earn 1,000 points. <br />
                          <br />
                          <strong>NOTE:</strong> Tweets must be live on your account at the time of snapshot, otherwise
                          points will be invalid.
                        </span>
                      </Tooltip>
                    </div>
                    <TipText>Earn 100 points for sharing. Bonuses are 1,000 points.</TipText>

                    <TodaysFortune
                      fortuneText={
                        fortunes[fortuneIndex] ||
                        'When it comes to real estate, the best time to buy was yesterday. The second best time is now.'
                      }
                      twitterData={twitterData}
                    />
                  </Section>
                </FixedContent>

                {/* This only renders on desktop */}
                <InvestorsWidget containerStyle={tw`mt-6 hidden md:flex`} />
              </Column>

              <GhostLogo
                mode={'dark'}
                parentRef={containerRef}
                isAtBottomStyles={tw` absolute mr-[-18rem] md:mr-[-16rem]`}
                notAtBottomStyles={tw` fixed mr-[-15rem] right-[-2rem]`}
              />
            </>
          ) : (
            <>Loading...</>
          )}
        </Container>
      </OuterContainer>

      {showWelcomeModal && (
        <WhitelistConfirmationModal
          isOpen={true}
          onClose={() => {
            setShowWelcomeModal(false)
          }}
        />
      )}
    </>
  )
}

export default PointsHome
