// Tooltip.js
import React from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

const TipContent = styled.div`
  ${tw`text-sm font-sans text-white font-light bg-gray-800 p-4 -m-2 rounded-lg`}
`

const StyledInfoIcon = styled(InfoIcon)`
  ${tw`transition-all duration-300 h-4 w-4 ml-2 cursor-pointer text-gray-500`}
  ${({ custom }) =>
    custom &&
    css`
      ${custom}
    `}
`

const Tooltip = ({ children, iconClassName, custom, className, style, ...props }) => {
  return (
    <Tippy
      content={
        <TipContent className={className} style={style}>
          {children}
        </TipContent>
      }
      {...props}
    >
      <StyledInfoIcon className={iconClassName} custom={custom} />
    </Tippy>
  )
}

export default Tooltip
