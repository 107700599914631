import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

import SimpleFiveColumn from 'components/footers/SimpleFiveColumn.js'

import Amplitude from 'amplitude-js'

import JustText from 'components/hero/JustText'

export default () => {
  const HighlightedText = styled.span`
    ${tw`font-medium`}
  `
  const Container = tw.div`pt-0 pb-8 px-4 md:px-8 bg-bone min-h-screen overflow-hidden font-display`
  Amplitude.getInstance().logEvent('page viewed', {
    pageName: 'contact',
    url: window.location.href,
    referrerUrl: document.referrer,
  })

  return (
    <Container>
      <JustText
        mode={'light'}
        roundedHeaderButton={true}
        title={'Get in Touch'}
        heading={
          <>
            Contact the <HighlightedText>Truly</HighlightedText> team.
          </>
        }
        subheading={
          <>
            For press inquiries, partnerships, or questions about the protocol, please send us a message. Someone from
            our team will respond shortly.
          </>
        }
        showButton={true}
        buttonText={'Send message'}
        buttonOnClick={() => {
          window.open('mailto:hello@trulybased.com', '_blank')
        }}
        showGhostLogo={true}
        isAtBottomStyles={tw` absolute mr-[-15rem] md:mr-[-16rem]`}
        notAtBottomStyles={tw` fixed mr-[-15rem] right-[-2rem]`}
      />

      <SimpleFiveColumn />
    </Container>
  )
}
