import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'
import { motion } from 'framer-motion'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Amplitude from 'amplitude-js'
import mailchimpConfig from '../../config/mailchimp'

import { PrimaryButton } from 'components/misc/Buttons'
import { useModal } from '../../contexts/ModalContext'

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
`

const PrimaryButtonError = styled(PrimaryButton)`
  ${tw`bg-red-700 hover:bg-red-900 font-semibold text-sm word-spacing-normal tracking-normal normal-case`}
`

const CollapsedForm = styled.div`
  input {
    ${tw`font-sans font-normal pl-8 py-4 rounded-2xl border-2 w-full focus:outline-none transition duration-300 focus:border-aqua-500 hover:border-gray-500`}
  }
`

const { postUrl } = mailchimpConfig

const CustomForm = ({ status, message, onValidated, userType, modalType, mode, buttonLabel = 'Join Waitlist' }) => {
  const [email, setEmail] = useState('')
  const [buttonText, setButtonText] = useState(buttonLabel)
  const [buttonErrorState, setButtonErrorState] = useState(false)
  const { openModal } = useModal()

  useEffect(() => {
    if (status === 'success') {
      openModal({
        type: modalType,
        userType: userType,
        email: email,
      })
      clearFields()
      resetButton()
      Amplitude.getInstance().logEvent('email form successfully submitted', { userType: userType })
    }
    if (status === 'error') {
      // There are a few different mailchimp errors. Errors related to a particular field have the field index followed by " - ".
      // The only other error I've seen is when the email address is already subscribed.
      // What gets returned is html where the user can update their profile.
      // We can't handle that response with the way we update state, so instead we use a fixed response.
      const cleanedText = message.includes(' - ') ? message.split(' - ')[1] : 'That email address is already subscribed'
      setButtonText(cleanedText)
      setButtonErrorState(true)
      Amplitude.getInstance().logEvent('email form error', { userType: userType, error: cleanedText })
    }
  }, [status])

  const clearFields = () => {
    setEmail('')
  }

  const resetButton = () => {
    setButtonErrorState(false)
    setButtonText(buttonLabel)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    email &&
      onValidated({
        MERGE0: email,
        MERGE2: userType,
      })
  }

  return (
    <form>
      <motion.div
        layout
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 15,
        }}
      >
        <CollapsedForm>
          <input
            type="text"
            placeholder="Your E-mail Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          {buttonErrorState ? (
            <PrimaryButtonError
              onClick={(e) => {
                // Just keeping this to prevent default
                handleSubmit(e)
              }}
            >
              {buttonText}
            </PrimaryButtonError>
          ) : (
            <PrimaryButton
              onClick={(e) => {
                // Just keeping this to prevent default
                handleSubmit(e)
              }}
            >
              {buttonText}
            </PrimaryButton>
          )}
        </CollapsedForm>
      </motion.div>
    </form>
  )
}

export default ({ mode, userType, modalType, buttonLabel }) => {
  return (
    <Actions>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <motion.div layout>
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
              mode={mode}
              userType={userType}
              modalType={modalType}
              buttonLabel={buttonLabel}
            />
          </motion.div>
        )}
      />
    </Actions>
  )
}
