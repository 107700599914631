import React, { useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import Amplitude from 'amplitude-js'

import { FaTwitter } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa'

const SocialLinksContainer = tw.div`mt-4 md:mt-0 lg:mt-0 flex px-8`
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full hover:bg-turquoise-light text-gray-700 hover:text-white transition duration-300 mr-8 last:mr-0`}
  svg {
    ${tw`w-5 h-5`}
  }
`

export default () => {
  return (
    <SocialLinksContainer>
      <SocialLink href="https://twitter.com/trulyhq" target="_blank" rel="noopener noreferrer">
        <FaTwitter />
      </SocialLink>
      <SocialLink href="https://t.me/trulybasedchat" target="_blank" rel="noopener noreferrer">
        <FaTelegramPlane />
      </SocialLink>
    </SocialLinksContainer>
  )
}
