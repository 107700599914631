import React, { useEffect, useState, useRef, useContext } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'

import Header from '../headers/light.js'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import GhostLogo from 'components/misc/GhostLogo.js'
import { InvestorsWidget } from 'components/misc/InvestorsWidget.js'
import { LocationContext } from 'contexts/LocationContext.js'

const Container = styled.div`
  ${tw`relative min-h-144 -mx-12 flex items-center justify-center p-12`}
  min-height: calc(100vh - 112px);
  @media (max-width: 768px) {
    min-height: ${({ isInUS }) =>
      isInUS
        ? 'calc(100vh - 118px - 48px)'
        : 'calc(100vh - 118px)'}; /* Adjusted min-height for mobile screens based on location */
  }
  ${({ containerStyles }) =>
    containerStyles &&
    css`
      ${containerStyles}
    `}/* Conditionally apply custom styles */
`

const InnerContainer = tw.div`xl:w-[1280px] relative z-10`

const HeroContainer = tw.div`z-20 flex flex-col max-w-screen-xl lg:w-3/4 max-w-[1200px]`

const Title = tw.p`font-bold font-sans tracking-widest word-spacing-wide uppercase text-turquoise text-sm md:text-base mb-8`
const Heading = tw.h1`font-light font-serif text-4xl md:text-5xl xl:text-6xl text-tertiary-700 leading-tight`
const Subheading = tw.p`font-light font-sans text-blue-500 my-4 text-base xl:text-lg md:w-3/4`

const PrimaryButton = tw(PrimaryButtonBase)`z-50 text-sm inline-block md:mx-0 max-w-64 text-center mt-4 mb-8 md:mb-4`

const WarningBox = styled.div`
  ${tw`bg-red-500 text-white text-lg font-sans font-medium p-4 rounded-lg mt-4`}
`

const SplashContainer = tw.div`hidden absolute inset-0 lg:flex flex-col items-end justify-center text-gray-100 opacity-[20%]`
const Splash = tw.div`font-light font-sans text-[31rem] leading-[26rem] relative right-[-1rem]`
const SplashLabel = tw.div`font-light font-sans text-[7rem] leading-[7rem]`

const MobileSplashContainer = tw.div`2xl:hidden xl:hidden lg:hidden font-light font-sans text-gray-100 flex flex-row items-end justify-start`
const MobileSplash = tw.div`text-[6rem] leading-[6.5rem] opacity-[35%]`
const MobileSplashLabel = tw.div`text-[3rem] opacity-[25%] pl-4`

const ButtonInvestorContainer = styled.div`
  ${tw`flex items-stretch justify-start mt-4 md:flex-row flex-col`}
`
const VertDivider = styled.div`
  ${tw`bg-gray-300 mx-12 hidden md:block w-[1px]`}
`

export default ({
  heading,
  subheading,
  title,
  roundedHeaderButton,
  hideHeaderBorder = false,
  headerMode,
  mode,
  showButton,
  buttonText,
  buttonOnClick,
  showSplash,
  showGhostLogo,
  isAtBottomStyles,
  notAtBottomStyles,
  content,
  containerStyles,
  showInvestors,
  warning,
  bonusContent,
}) => {
  const containerRef = useRef(null)
  const [isAtBottom, setIsAtBottom] = useState(false)
  const { isInUS } = useContext(LocationContext)

  const checkIfAtBottom = () => {
    if (!containerRef.current) return

    const { bottom } = containerRef.current.getBoundingClientRect()
    setIsAtBottom(window.innerHeight >= bottom)
  }

  useEffect(() => {
    window.addEventListener('scroll', checkIfAtBottom)
    window.addEventListener('resize', checkIfAtBottom)
    checkIfAtBottom() // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfAtBottom)
      window.removeEventListener('resize', checkIfAtBottom)
    }
  }, [])

  return (
    <>
      <Header
        roundedHeaderButton={roundedHeaderButton}
        mode={headerMode == 'dark' ? 'dark' : 'light'}
        hideBorder={hideHeaderBorder}
      />
      <Container ref={containerRef} isInUS={isInUS} css={[mode == 'dark' && tw`bg-gray-900`, containerStyles]}>
        <InnerContainer>
          <HeroContainer>
            <Title>{title}</Title>
            <Heading css={[mode == 'dark' && tw`text-gray-100`]}>{heading}</Heading>
            {bonusContent && <div>{bonusContent}</div>}
            <Subheading css={[mode == 'dark' && tw`text-gray-100`]}>{subheading}</Subheading>

            {showSplash && !isInUS && (
              <MobileSplashContainer>
                <MobileSplash>13%</MobileSplash>
                <MobileSplashLabel>APY</MobileSplashLabel>
              </MobileSplashContainer>
            )}

            {showButton && !showInvestors && (
              <PrimaryButton as="a" onClick={buttonOnClick}>
                {buttonText}
              </PrimaryButton>
            )}

            {warning && <WarningBox>{warning}</WarningBox>}

            {showButton && showInvestors && (
              <ButtonInvestorContainer>
                <PrimaryButton as="a" onClick={buttonOnClick}>
                  {buttonText}
                </PrimaryButton>
                <VertDivider />
                <InvestorsWidget />
              </ButtonInvestorContainer>
            )}

            {content}
          </HeroContainer>
          {showSplash && !isInUS && (
            <SplashContainer>
              <Splash>13%</Splash>
              <SplashLabel>APY</SplashLabel>
            </SplashContainer>
          )}
        </InnerContainer>

        {showGhostLogo && (
          <GhostLogo
            mode={mode}
            parentRef={containerRef}
            isAtBottomStyles={isAtBottomStyles}
            notAtBottomStyles={notAtBottomStyles}
          />
        )}
      </Container>
    </>
  )
}
