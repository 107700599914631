const fortunes = [
  'He who combines land and ledger, paves the road to prosperity.',
  'Investor who marries blockchain and bricks, finds unbreakable wealth.',
  'He who holds tokenized homes, holds the keys to future riches.',
  'In crypto and real estate, a diversified portfolio blooms.',
  'Man who invests in digital deeds, secures legacy.',
  'He who converts crypto gains to real estate, builds unshakeable empire.',
  'Investor who sees value in tokens, sees value in the world.',
  'Smart investor knows: properties on blockchain, fortune in hand.',
  'He who tokenizes real estate, locks value in code.',
  'Secure your digital wealth in physical assets, and peace follows.',
  'Wise investor balances digital and real, reaping rewards in both.',
  'In blockchain and bricks, the future of wealth is built.',
  'Investor who sees blockchain as foundation, builds skyscrapers of wealth.',
  'He who bridges crypto with real estate, spans oceans of opportunity.',
  'In crypto and concrete, find a fortress of fortune.',
  'He who sees value in land and ledger, sees future clearly.',
  'Investor who holds tokens and titles, holds the world.',
  'In the union of blockchain and buildings, wealth is multiplied.',
  'He who anchors wealth in tokenized land, never drifts.',
  'Man who merges blockchain with real estate, builds unshakable foundation.',
  'He who invests in digital deeds, invests in the future.',
  'He who holds tokenized property, holds limitless potential.',
  'Investor who balances digital and real, secures fortune in all realms.',
  'In the realm of blockchain and bricks, the wise investor prospers.',
  'Man who anchors his crypto in real estate, ensures his wealth is not adrift.',
  'Wise man secures digital wealth with concrete foundations.',
  'In crypto, even small fish can own big pond.',
  'Secure your wealth in real estate, and secure your peace of mind.',
  'Investor without RWAs in crypto portfolio, sails without anchor.',
  'Real assets tokenized, real wealth realized.',
  'Global investor reaches local shores through crypto seas.',
  'Like lotus blooms on muddy water, blockchain thrives amidst economic turmoil.',
  'He who merges crypto with real estate, architects his own fortune.',
  'Permissionless tokens grant permission to many worlds.',
  'Wise is the investor who sees beyond the screen to the land beneath.',
  'In the realm of RWAs, Truly is the master key unlocking global vaults.',
  'Blockchain-powered fund, like dragon, powerful and majestic.',
  'He who ignores real estate on blockchain, finds himself in a room with no doors.',
  'Real estate investor without crypto, like fisherman without net.',
  'He who buys real world assets, captures fortune in all realms.',
  'Wise investor uses token, not token gestures.',
  'He who taps into RWAs, taps into wellsprings of wealth.',
  'Permissionless tokens bring distant shores closer.',
  'Easiest way to own U.S. home from afar is through token, not travel.',
  'He who secures home equity on blockchain, secures future.',
  'Token holder is the new landholder.',
  'He who invests in tokenized real estate, builds empire on the chain.',
  'He who seeks U.S. real estate from afar, finds home in crypto.',
  'Smart contract makes smarter investor.',
  'Digital token carries home across continents.',
  'Real estate and blockchain, ancient asset meets modern method.',
  'Blockchain is the loom that weaves physical assets with digital threads.',
  'Man who sees future, sees RWAs in his crypto portfolio.',
  'Modern wisdom invests in old asset through new means.',
  'House built on blockchain, never collapses.',
  'He who buys USH, shelters from financial storm.',
  'Invest in U.S. homes without ever setting foot inside.',
  'He who embraces blockchain, embraces global wealth.',
  'Man who misses crypto wave, swims against current of progress.',
  'Token is key to the doors of best investment opportunities',
  'Embrace RWAs on blockchain, and grasp the globe in your portfolio.',
  'Man who invests in real estate on crypto, sleeps peacefully.',
  'Investor who overlooks crypto, misses token of wisdom.',
  'He who seeks easy real estate, finds it on crypto.',
  'Reside digitally, invest globally.',
  'Diversify on blockchain, and find wealth as vast as sea.',
]

export default fortunes
