import styled, { css, keyframes } from 'styled-components'

const arrowBounce = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`

const Arrow = styled.span`
  ${({ animate }) =>
    animate &&
    css`
      &:before {
        content: '➔'; /* Arrow icon */
        display: inline-block;
        margin-right: 8px;
        animation: ${arrowBounce} 1s ease-in-out infinite;
      }
    `}
`

export default Arrow
