// WhitelistConfirmationModal.js
import React, { useEffect } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

import LightLogo from '../../assets/logos/iconLight.svg'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons'

const ModalBackdrop = tw.div`fixed inset-0 bg-black opacity-[0.8] z-[100]`
const ModalContainer = tw.div`fixed inset-0 flex flex-col items-center justify-center text-center z-[100] mx-4 md:mx-0`
const ModalContent = tw.div`overflow-hidden rounded-lg border-turquoise border-[3px] shadow-crypto-lg p-8 bg-gray-900  mx-auto text-left flex flex-row`
const TextContent = tw.div`ml-12 text-left flex flex-col justify-between`
const Heading = tw.h1`font-light font-serif text-2xl md:text-4xl text-white leading-tight`
const Subheading = tw.p`font-light font-sans text-white my-4 text-sm md:text-base xl:text-lg md:max-w-[360px]`
const ModalButton = styled(PrimaryButtonBase)`
  ${tw`rounded md:rounded-xl text-sm md:text-base m-0 h-8 min-w-[110px] md:h-12 md:max-w-[182px] bg-turquoise hover:bg-turquoise-light transition-all duration-300 place-self-end`}
`

const GhostLogo = styled.div`
  ${tw`right-0 ml-[-12rem] opacity-50`}
`

const GhostLogoImage = tw.img`w-[20rem]`

const HighlightedText = styled.span`
  ${tw`font-medium`}
`
const VeryHighlightedText = styled.span`
  ${tw`font-semibold`}
`

// Assuming `isOpen` is passed as a prop to control the visibility of the modal
const WhitelistConfirmationModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => (document.body.style.overflow = originalStyle) // revert back to the initial style when the modal is closed
  }, [isOpen])

  if (!isOpen) return null

  return (
    <>
      <ModalBackdrop />
      <ModalContainer>
        <ModalContent>
          <GhostLogo>
            <GhostLogoImage src={LightLogo} alt="Ghost" />
          </GhostLogo>
          <TextContent>
            <div>
              <Heading>
                Congratulations! <br /> <HighlightedText>You're whitelisted.</HighlightedText>
              </Heading>
              <Subheading>
                You just earned <VeryHighlightedText>420 points</VeryHighlightedText>. Continue earning points to
                maximize your future <VeryHighlightedText>airdrop.</VeryHighlightedText>
              </Subheading>
            </div>
            <ModalButton onClick={onClose}>Proceed</ModalButton>
          </TextContent>
        </ModalContent>
      </ModalContainer>
    </>
  )
}

export default WhitelistConfirmationModal
