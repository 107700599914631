import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import NumberFormat from 'react-number-format'

import { PrimaryButton } from 'components/misc/Buttons.js'
import { LocationContext } from '../../contexts/LocationContext'
import mailchimpConfig from '../../config/mailchimp'

const Form = tw.form`space-y-4`
const Input = tw.input`font-sans font-normal pl-8 py-4 rounded border-2 w-full focus:outline-none transition duration-300 border-gray-500 focus:border-aqua-500 hover:border-gray-500 mb-4`
const CheckboxGroup = tw.div`flex items-center space-x-2 mb-4`
const Checkbox = tw.input`rounded text-indigo-600 focus:ring-indigo-500 border-gray-300`
const CheckboxLabel = tw.label`font-sans block text-base font-light text-blue-500`
const ModalLabel = tw.label`font-sans block text-base font-light text-blue-500 mb-2`
const SuccessMessageContainer = tw.div`text-center p-10`
const SuccessTitle = tw.h2`text-2xl font-bold font-sans text-aqua-500 mb-4`
const SuccessDescription = tw.p`text-base font-light font-sans text-blue-500`
const PrimaryButtonError = styled(PrimaryButton)`
  ${tw`bg-red-700 hover:bg-red-900 font-semibold text-sm`}
`
const CheckboxContainer = tw.div`pt-2`

const { postUrl } = mailchimpConfig

const RetailLeadCapture = ({ userEmail, userType }) => {
  const { isInUS } = useContext(LocationContext)
  const [email, setEmail] = useState(userEmail)
  const [amount, setAmount] = useState('')
  const [isNotUSInvestor, setIsNotUSInvestor] = useState(false)
  const [isProfessionalInvestor, setIsProfessionalInvestor] = useState(false)
  const [representsInstitution, setRepresentsInstitution] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [buttonText, setButtonText] = useState('Next')
  const [buttonErrorState, setButtonErrorState] = useState(false)

  const handleAmountChange = (event) => {
    // Remove all non-digit characters for processing
    const value = event.target.value.replace(/[^0-9.]/g, '')
    // Format the number with commas and currency symbol for display
    const formattedAmount = value
      ? parseFloat(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2, // You may want to allow cents
        })
      : ''

    // Update state with the formatted value
    setAmount(formattedAmount)
  }

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target
    switch (id) {
      case 'professional-investor':
        setIsProfessionalInvestor(checked)
        break
      case 'institution-representative':
        setRepresentsInstitution(checked)
        break
      case 'not-us-investor': // Handle the new checkbox
        setIsNotUSInvestor(checked)
        break
      default:
        break
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  // Prepare the data in the format required by Mailchimp
  const prepareFormData = () => ({
    MERGE0: email,
    MERGE2: userType,
    MERGE10: amount,
    MERGE11: isNotUSInvestor ? 'Yes' : 'No',
    MERGE12: isProfessionalInvestor ? 'Yes' : 'No',
    MERGE13: representsInstitution ? 'Yes' : 'No',
  })

  const handleSubmit = (e, subscribe) => {
    e.preventDefault()
    const formData = prepareFormData()
    if (email) {
      // Call the subscribe function from react-mailchimp-subscribe
      subscribe(formData)
    }
  }

  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => {
        // Error handling
        if (status === 'error' && !formSubmitted) {
          setButtonErrorState(true)
          // Clean up the error message from Mailchimp if needed
          setButtonText(message.includes(' - ') ? message.split(' - ')[1] : message)
        } else if (status === 'success') {
          setFormSubmitted(true)
          setButtonErrorState(false)
        }

        if (status === 'success' && !formSubmitted) {
          setFormSubmitted(true)
        }

        // If the form was submitted successfully, render the success message
        if (formSubmitted) {
          return (
            <SuccessMessageContainer>
              <SuccessTitle>Thank You!</SuccessTitle>
              <SuccessDescription>
                Your information was successfully submitted. You've been added to the waitlist.
              </SuccessDescription>
            </SuccessMessageContainer>
          )
        }
        return (
          <Form onSubmit={(e) => handleSubmit(e, subscribe)}>
            {/* Email Input */}
            <div>
              <ModalLabel htmlFor="email">What's your email address?</ModalLabel>
              <Input id="email" type="email" placeholder="Email Address" value={email} onChange={handleEmailChange} />
            </div>
            {/* Amount Input */}
            <div>
              <ModalLabel htmlFor="amount">How much do you want to invest?</ModalLabel>
              <NumberFormat
                id="amount"
                customInput={Input}
                value={amount}
                thousandSeparator={true}
                prefix={'$'}
                onValueChange={(values) => {
                  const { value } = values
                  // You get only the value without formatting here
                  setAmount(value)
                }}
                placeholder="$100,000"
                inputMode="decimal"
              />
            </div>

            <hr />

            {/* Checkboxes */}
            <CheckboxContainer>
              {!isInUS && (
                <CheckboxGroup>
                  <Checkbox
                    id="not-us-investor"
                    type="checkbox"
                    checked={isNotUSInvestor}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel htmlFor="not-us-investor">I am not a U.S. investor.</CheckboxLabel>
                </CheckboxGroup>
              )}
              <CheckboxGroup>
                <Checkbox
                  id="professional-investor"
                  type="checkbox"
                  checked={isProfessionalInvestor}
                  onChange={handleCheckboxChange}
                />
                <CheckboxLabel htmlFor="professional-investor">
                  {isInUS ? 'I am an accredited investor.' : 'I am a professional investor.'}
                </CheckboxLabel>
              </CheckboxGroup>

              <CheckboxGroup>
                <Checkbox
                  id="institution-representative"
                  type="checkbox"
                  checked={representsInstitution}
                  onChange={handleCheckboxChange}
                />
                <CheckboxLabel htmlFor="institution-representative">I represent an institution.</CheckboxLabel>
              </CheckboxGroup>
            </CheckboxContainer>

            {/* Submit Button */}
            {buttonErrorState ? (
              <PrimaryButtonError type="submit">{buttonText}</PrimaryButtonError>
            ) : (
              <PrimaryButton type="submit">{buttonText}</PrimaryButton>
            )}
          </Form>
        )
      }}
    />
  )
}

export default RetailLeadCapture
