import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import { PrimaryButton } from 'components/misc/Buttons.js'
import { LocationContext } from '../../contexts/LocationContext' // Adjust the path as necessary
import mailchimpConfig from '../../config/mailchimp'

const Form = tw.form`space-y-4`
const Input = tw.input`font-sans font-normal pl-8 py-4 rounded border-2 w-full focus:outline-none transition duration-300 border-gray-500 focus:border-aqua-500 hover:border-gray-500 mb-4`
const CheckboxGroup = tw.div`flex items-center space-x-2 mb-4`
const Checkbox = tw.input`rounded text-indigo-600 focus:ring-indigo-500 border-gray-300`
const CheckboxLabel = tw.label`font-sans block text-base font-light text-blue-500`
const ModalLabel = tw.label`font-sans block text-base font-light text-blue-500 mb-2`
const SuccessMessageContainer = tw.div`text-center p-10`
const SuccessTitle = tw.h2`text-2xl font-bold font-sans text-aqua-500 mb-4`
const SuccessDescription = tw.p`text-base font-light font-sans text-blue-500`
const PrimaryButtonError = styled(PrimaryButton)`
  ${tw`bg-red-700 hover:bg-red-900 font-semibold text-sm`}
`
const CheckboxContainer = tw.div`pt-2`

const { postUrl } = mailchimpConfig

const AdvisorLeadCapture = ({ userEmail, userType }) => {
  const { isInUS } = useContext(LocationContext)
  const [fullName, setFullName] = useState('')
  const [institutionName, setInstitutionName] = useState('')
  const [email, setEmail] = useState(userEmail)
  const [representsNonUSClients, setRepresentsNonUSClients] = useState(false)
  const [advisesAccreditedInvestors, setAdvisesAccreditedInvestors] = useState(false) // New state for accredited investors
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [buttonText, setButtonText] = useState('Next')
  const [buttonErrorState, setButtonErrorState] = useState(false)

  const handleInputChange = (event) => {
    const { id, value } = event.target
    switch (id) {
      case 'full-name':
        setFullName(value)
        break
      case 'institution-name':
        setInstitutionName(value)
        break
      case 'email':
        setEmail(value)
        break
      default:
        break
    }
  }

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target
    if (id === 'non-us-clients') {
      setRepresentsNonUSClients(checked)
    } else if (id === 'accredited-investors') {
      setAdvisesAccreditedInvestors(checked)
    }
  }

  const prepareFormData = () => ({
    MERGE0: email,
    MERGE2: userType,
    MERGE14: fullName,
    MERGE15: institutionName,
    MERGE18: representsNonUSClients ? 'Yes' : 'No',
    MERGE19: advisesAccreditedInvestors ? 'Yes' : 'No',
  })

  const handleSubmit = (e, subscribe) => {
    e.preventDefault()
    const formData = prepareFormData()
    subscribe(formData)
  }

  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => {
        // Error handling
        if (status === 'error' && !formSubmitted) {
          setButtonErrorState(true)
          // Clean up the error message from Mailchimp if needed
          setButtonText(message.includes(' - ') ? message.split(' - ')[1] : message)
        } else if (status === 'success') {
          setFormSubmitted(true)
          setButtonErrorState(false)
        }

        if (status === 'success' && !formSubmitted) {
          setFormSubmitted(true)
        }

        // If the form was submitted successfully, render the success message
        if (formSubmitted) {
          return (
            <SuccessMessageContainer>
              <SuccessTitle>Thank you!</SuccessTitle>
              <SuccessDescription>
                Your information was successfully submitted. You've been added to the waitlist.
              </SuccessDescription>
            </SuccessMessageContainer>
          )
        }
        return (
          <Form onSubmit={(e) => handleSubmit(e, subscribe)}>
            {/* Full Name Input */}
            <div>
              <ModalLabel htmlFor="full-name">Full Name</ModalLabel>
              <Input
                id="full-name"
                type="text"
                placeholder="First Last"
                value={fullName}
                onChange={handleInputChange}
              />
            </div>

            {/* Institution Name Input */}
            <div>
              <ModalLabel htmlFor="institution-name">Firm Name</ModalLabel>
              <Input
                id="institution-name"
                type="text"
                placeholder="Firm Name"
                value={institutionName}
                onChange={handleInputChange}
              />
            </div>

            {/* Email Input */}
            <div>
              <ModalLabel htmlFor="email">Email Address</ModalLabel>
              <Input id="email" type="email" placeholder="Email Address" value={email} onChange={handleInputChange} />
            </div>

            <hr />

            <CheckboxContainer>
              {/* Single Checkbox */}
              <CheckboxGroup>
                <Checkbox
                  id="non-us-clients"
                  type="checkbox"
                  checked={representsNonUSClients}
                  onChange={handleCheckboxChange}
                />
                <CheckboxLabel htmlFor="non-us-clients">I represent non-U.S. clients.</CheckboxLabel>
              </CheckboxGroup>

              <CheckboxGroup>
                <Checkbox
                  id="accredited-investors"
                  type="checkbox"
                  checked={advisesAccreditedInvestors}
                  onChange={handleCheckboxChange}
                />
                <CheckboxLabel htmlFor="accredited-investors">
                  {isInUS
                    ? 'I advise clients who are accredited investors.'
                    : 'I advise clients who are professional investors.'}
                </CheckboxLabel>
              </CheckboxGroup>
            </CheckboxContainer>

            {/* Submit Button */}
            {buttonErrorState ? (
              <PrimaryButtonError as="div">{buttonText}</PrimaryButtonError>
            ) : (
              <PrimaryButton type="submit">{buttonText}</PrimaryButton>
            )}
          </Form>
        )
      }}
    />
  )
}

export default AdvisorLeadCapture
