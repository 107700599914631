let mailchimpConfig = {}

// TODO: Move API key to env variable. Is this important?
if (process.env.NODE_ENV === 'development') {
  mailchimpConfig = {
    postUrl: `https://gmail.us14.list-manage.com/subscribe/post?u=e96f51366021502826868c48d&id=71b23743f1`,
  }
} else {
  mailchimpConfig = {
    postUrl: `https://gmail.us14.list-manage.com/subscribe/post?u=e96f51366021502826868c48d&id=71b23743f1`,
  }
}

module.exports = mailchimpConfig
