// Divider.js
import tw from 'twin.macro'
import styled, { css } from 'styled-components'

const DividerFoo = styled.div`
  ${tw`w-3/4 border-t border-gray-400 mx-8`}
  ${({ customStyle }) => customStyle && customStyle}
`

const Container = tw.div`flex justify-center`

export const Divider = ({ customStyle }) => {
  return (
    <Container>
      <DividerFoo customStyle={customStyle} />
    </Container>
  )
}
