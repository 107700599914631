import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { css } from 'styled-components/macro'

import DarkLogo from '../../assets/logos/iconDark.svg'
import LightLogo from '../../assets/logos/iconLight.svg'

const GhostLogoContainer = styled.div`
  ${tw`right-0 bottom-0 opacity-[15%] md:opacity-50`}
  ${({ isAtBottom, isAtBottomStyles, notAtBottomStyles }) =>
    isAtBottom
      ? css`
          ${isAtBottomStyles}
        `
      : css`
          ${notAtBottomStyles}
        `};
`

const GhostLogoImage = tw.img`w-[34rem]`

const GhostLogo = ({ mode, parentRef, isAtBottomStyles, notAtBottomStyles }) => {
  const [isAtBottom, setIsAtBottom] = useState(false)

  const checkIfAtBottom = () => {
    if (!parentRef.current) return

    const parentBottom = parentRef.current.getBoundingClientRect().bottom
    setIsAtBottom(window.innerHeight >= parentBottom)
  }

  useEffect(() => {
    window.addEventListener('scroll', checkIfAtBottom)
    window.addEventListener('resize', checkIfAtBottom)
    checkIfAtBottom() // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfAtBottom)
      window.removeEventListener('resize', checkIfAtBottom)
    }
  }, [parentRef])

  return (
    <GhostLogoContainer
      isAtBottom={isAtBottom}
      isAtBottomStyles={isAtBottomStyles}
      notAtBottomStyles={notAtBottomStyles}
    >
      <GhostLogoImage src={mode === 'light' ? DarkLogo : LightLogo} />
    </GhostLogoContainer>
  )
}

export default GhostLogo
