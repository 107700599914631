import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import styled, { keyframes } from 'styled-components'
import { css } from 'styled-components/macro'
import { httpsCallable } from 'firebase/functions'
import { usePrivy } from '@privy-io/react-auth'
import Amplitude from 'amplitude-js'

import { useWallet } from '../../contexts/WalletContext.js'
import { functions } from '../../App'
import ProcessingModal from './ProcessingModal'
import { Divider } from 'components/misc/Divider'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons'
import Tooltip from 'components/misc/Tooltip.js'
import CheckBadge from 'assets/misc/checkBadge.svg'
import { awardReferralCodeUsedPoints } from '../../utils/referralUtils'

const QuestContainer = styled.div`
  ${tw`mt-10 w-full xl:w-10/12 xl:max-w-[50rem] `}
`

const InnerStepContainer = styled.div`
  ${tw`flex flex-row items-center justify-between w-full gap-4`}
`

const Step = styled.div`
  ${tw`flex flex-row items-center mb-0`}
`
const StyledNumberOuterContainer = styled.div`
  ${tw`min-w-[3rem] md:min-w-[6rem]`}
`
const StyledNumberContainer = styled.div`
  ${tw`rounded-full bg-gray-100 w-10 h-10 md:w-16 md:h-16 flex items-center justify-center`}
`
const StyledNumber = styled.span`
  ${tw`text-gray-900 font-serif font-normal md:font-light text-2xl md:text-5xl leading-none`}
`
const PointsContainer = styled.div`
  ${tw`flex flex-row`}
`

const StepSubtitle = styled.span`
  ${tw`text-2xs md:text-xs text-gray-500 font-sans font-semibold uppercase`}
`

const StepTitle = styled.h2`
  ${tw`text-base md:text-xl text-white font-sans font-light`}
`

const StepTip = styled.span`
  ${tw`text-2xs md:text-xs text-gray-500 font-sans font-semibold`}
`
const Link = tw.a`transition-all duration-300 font-bold text-turquoise hover:underline`

const StepInfo = styled.div`
  ${tw`flex flex-col items-start justify-center`}
`

const ButtonSpace = styled.div`
  ${tw`flex flex-col md:flex-row items-end md:items-start justify-center relative`}
`

const SubmitSpace = styled.div`
  ${tw`flex flex-col items-end justify-center`}
  order: 0; // Ensures this is on top in mobile view
  ${tw`md:order-2`}// Changes order on desktop so it comes after Input
`

const VerificationSpace = styled.div`
  ${tw`absolute top-[38px] md:top-[55px]`}
  ${({ customStyle }) => customStyle && customStyle}
`

const VerificationButton = styled.button``

const arrowBounce = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`

const VerificationMessage = styled.span`
  ${tw`text-2xs md:text-xs text-white font-sans uppercase font-semibold relative inline-flex items-center`}
  ${({ disabled }) => disabled && tw`text-gray-500 cursor-not-allowed no-underline`}
  ${({ disabled, as }) => !disabled && as === 'button' && tw`underline`}
`

const Arrow = styled.span`
  ${({ animate }) =>
    animate &&
    css`
      &:before {
        content: '➔'; /* Arrow icon */
        display: inline-block;
        margin-right: 8px;
        animation: ${arrowBounce} 1s ease-in-out infinite;
      }
    `}
`

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255,255,255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
`

const Button = styled(PrimaryButtonBase)`
  ${tw`rounded md:rounded-xl text-sm md:text-base m-0 h-8 min-w-[110px] md:h-12 md:min-w-[182px] shadow-crypto-normal bg-turquoise hover:bg-turquoise-light transition-all duration-300`}
  ${({ disabled }) => disabled && tw`bg-gray-600 hover:bg-gray-600 cursor-not-allowed shadow-none`}
  ${({ isVerifying }) =>
    isVerifying &&
    css`
      animation: ${pulseAnimation} 1.5s infinite;
    `}
`

const Input = styled.input`
  ${tw`h-8 w-[110px] md:h-12 md:min-w-[182px] bg-transparent text-white font-light font-sans text-base md:text-xl mr-0 md:mr-8 mb-3 md:mb-0 rounded md:rounded-xl md:px-4 flex items-center justify-center focus:outline-none transition duration-300 cursor-text border-gray-500 focus:border-white border-2 text-center`}

  &::placeholder {
    ${tw`text-gray-500`} // Placeholder text color
    letter-spacing: normal; // Specific letter-spacing for placeholder
  }

  /* Apply tracking-widest to user input */
  letter-spacing: 0.1em;
`

export const Quest = ({ onSubmit, walletAddress, customStyle, isMobile }) => {
  const [isAndroidMobile, setIsAndroidMobile] = useState(false)

  const [referralCode, setReferralCode] = useState('')
  const [isVerifyingReferralCode, setIsVerifyingReferralCode] = useState(false)
  const [isReferralCodeValid, setIsReferralCodeValid] = useState(false)
  const [referralCodeFeedback, setReferralCodeFeedback] = useState('')

  const [isTwitterConnectAttempted, setIsTwitterConnectAttempted] = useState(false)
  const [isVerifyingTwitterConnect, setIsVerifyingTwitterConnect] = useState(false)
  const [isTwitterConnectVerified, setIsTwitterConnectVerified] = useState(false)
  const [twitterConnectFeedback, setTwitterConnectFeedback] = useState('Click to verify')

  const [isTwitterAttempted, setIsTwitterAttempted] = useState(false)
  const [isVerifyingTwitter, setIsVerifyingTwitter] = useState(false)
  const [isTwitterFollowVerified, setIsTwitterFollowVerified] = useState(false)

  const [isRetweetAttempted, setIsRetweetAttempted] = useState(false)
  const [isVerifyingRetweet, setIsVerifyingRetweet] = useState(false)
  const [isRetweetVerified, setIsRetweetVerified] = useState(false)
  const [retweetFeedback, setRetweetFeedback] = useState('Click to verify')
  const [retweetFeedbackState, setRetweetFeedbackState] = useState('')

  const [isTelegramAttempted, setIsTelegramAttempted] = useState(false)
  const [isVerifyingTelegram, setIsVerifyingTelegram] = useState(false)
  const [isTelegramVerified, setIsTelegramVerified] = useState(false)

  // These are only used for Android mobile. Other users just connec with Privy
  const [twitterHandle, setTwitterHandle] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { userProgress } = useWallet()
  const { ready, authenticated, login, logout, user } = usePrivy()
  // Disable Privy when it's not ready or the user is already authenticated
  const disablePrivyConnection = !ready

  // Check if the user is coming from an Android
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // Check for Android in the user agent string
    if (/android/i.test(userAgent)) {
      setIsAndroidMobile(true)
      setTwitterConnectFeedback('')
    }
  }, [])

  // Monitor data we get from privy
  useEffect(() => {
    // If we have a user from privy, but we dont have a record of the twitter connect being verified, set the state to allow the user to verify themselves
    if (user?.twitter && !userProgress?.isTwitterConnectVerified) {
      setIsTwitterConnectAttempted(true)
    } else if (user?.twitter && userProgress?.isTwitterConnectVerified) {
      setIsTwitterConnectVerified(true)
    } else {
      setIsTwitterConnectAttempted(false)
    }
  }, [user])

  useEffect(() => {
    if (userProgress?.referralCodeRedeemedAt) {
      setIsReferralCodeValid(true)
    }
    if (userProgress?.isTwitterConnectVerified) {
      setIsTwitterConnectVerified(true)

      // In case the user refreshes/returns after inputting their twitter handle, try to fetch it
      if (!twitterHandle) {
        fetchTwitterUsername()
      }
    }
    if (userProgress?.isTwitterFollowVerified) {
      setIsTwitterFollowVerified(true)
    }
    if (userProgress?.isRetweetVerified) {
      setIsRetweetVerified(true)
    }
  }, [userProgress])

  const handleValidReferralCode = (code) => {
    setIsReferralCodeValid(true)
  }

  const fetchTwitterUsername = () => {
    const getTwitterDataByWalletAddress = httpsCallable(functions, 'getTwitterDataByWalletAddress')

    getTwitterDataByWalletAddress({ walletAddress: walletAddress })
      .then((result) => {
        console.log('Twitter fetch result', result)
        setTwitterHandle(result.data.twitterUsername)
      })
      .catch((err) => {
        console.error('Error fetching Twitter data:', err)
      })
  }

  const completeQuest = async () => {
    setIsSubmitting(true) // Indicate loading state

    // Prepare callable functions
    const assignReferralCode = httpsCallable(functions, 'assignReferralCode')
    const updateJoinTimestamp = httpsCallable(functions, 'updateJoinTimestamp')

    try {
      // Execute both functions concurrently
      await Promise.all([assignReferralCode({ walletAddress }), updateJoinTimestamp({ walletAddress })])
      console.log('Quest completed: Referral code assigned and join timestamp updated.')

      // Deliberately not clearing loading state because we want the listener on the user to trigger the component change in Points.js
      // setIsSubmitting(false) // Reset loading state

      onSubmit() // Notify the parent component that the submission is complete. This is not being used currently

      Amplitude.getInstance().logEvent('onboard complete', {
        pageName: 'quest',
        url: window.location.href,
        walletAddress: walletAddress,
      })
    } catch (error) {
      console.error('Error completing the quest:', error)
      setIsSubmitting(false) // Reset loading state in case of error
    }
  }

  const awardPointsForTwitterConnect = async () => {
    const pointsData = {
      walletAddress,
      points: 70,
      type: 'twitter_connect',
      note: '',
    }
    const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')

    try {
      const result = await createAndUpdatePoints(pointsData)
    } catch (error) {
      console.error('Failed to award points for Twitter connect:', error)
    }
  }

  const successStepsForRetweetVerified = async () => {
    setIsRetweetVerified(true)
    // Call function to update Retweet verification
    const updateRetweetVerification = httpsCallable(functions, 'updateRetweetVerification')
    try {
      await updateRetweetVerification({ walletAddress, isRetweetVerified: true })
      console.log('Retweet verification updated.')
    } catch (error) {
      console.error('Error updating Retweet verification:', error)
    }
    awardPointsForRetweet()
    Amplitude.getInstance().logEvent('retweet verified', {
      pageName: 'quest',
      retweetId: '1783212659627720813', // Specific tweet ID they needed to retweet
      url: window.location.href,
      walletAddress: walletAddress,
      actualVerification: true,
    })
  }

  const verifyRetweet = async () => {
    setIsVerifyingRetweet(true) // Start the verification process UI feedback
    setRetweetFeedback('Verifying...')

    // Prepare the callable function from Firebase
    const checkIfUserRetweeted = httpsCallable(functions, 'checkIfUserRetweeted')

    const twitterUsername = user?.twitter?.username || twitterHandle
    console.log('twitterUsername', twitterUsername)

    try {
      // Call the function with the correct parameters
      const result = await checkIfUserRetweeted({
        tweetId: '1783212659627720813', // This is the tweet ID of the post we want to verify
        username: twitterUsername,
      })

      if (result.data.hasRetweeted) {
        console.log('User has retweeted the post.')
        await successStepsForRetweetVerified()
      } else {
        console.log('User has not retweeted the post.', result.data)
        setIsRetweetVerified(false)
        setRetweetFeedback('Retweet not detected. Try again.')
        setRetweetFeedbackState('error')
      }
    } catch (error) {
      // console.error('Failed to verify retweet status:', error)
      // setRetweetFeedback('Failed' + error)
      // setRetweetFeedbackState('error')

      // There are draconian rate limits on the Twitter API. If this function fails, it's likely because we hit the limit.
      // As a result, just assume the user has retweeted.
      console.log('Assuming user has retweeted due to rate limit or some other error.')
      await successStepsForRetweetVerified()
    }

    setIsVerifyingRetweet(false) // End the verification process UI feedback
  }

  const awardPointsForRetweet = async () => {
    const pointsData = {
      walletAddress,
      points: 100,
      type: 'retweet_verification',
      note: '',
    }
    const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')

    try {
      const result = await createAndUpdatePoints(pointsData)
    } catch (error) {
      console.error('Failed to award points for Twitter verification:', error)
    }
  }

  const verifyTwitterFollow = () => {
    setIsVerifyingTwitter(true)
    setTimeout(async () => {
      setIsTwitterFollowVerified(true)
      setIsVerifyingTwitter(false)

      // Call function to update Twitter verification
      const updateTwitterVerification = httpsCallable(functions, 'updateTwitterVerification')
      try {
        await updateTwitterVerification({ walletAddress, isTwitterFollowVerified: true })
        console.log('Twitter verification updated.')
        // After successfully updating the verification, award points
        awardPointsForTwitter()

        Amplitude.getInstance().logEvent('twitter followed', {
          pageName: 'quest',
          url: window.location.href,
          walletAddress: walletAddress,
        })
      } catch (error) {
        console.error('Error updating Twitter verification:', error)
      }
    }, 2000)
  }

  const awardPointsForTwitter = async () => {
    const pointsData = {
      walletAddress,
      points: 100,
      type: 'twitter_verification',
      note: '',
    }
    const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')

    try {
      const result = await createAndUpdatePoints(pointsData)
    } catch (error) {
      console.error('Failed to award points for Twitter verification:', error)
    }
  }

  const verifyTelegramJoin = () => {
    setIsVerifyingTelegram(true)
    setTimeout(async () => {
      setIsTelegramVerified(true)
      setIsVerifyingTelegram(false)
      completeQuest()

      // Call function to update Telegram verification
      const updateTelegramVerification = httpsCallable(functions, 'updateTelegramVerification')
      try {
        await updateTelegramVerification({ walletAddress, isTelegramVerified: true })
        console.log('Telegram verification updated.')
        // After successfully updating the verification, award points
        awardPointsForTelegram()

        Amplitude.getInstance().logEvent('telegram joined', {
          pageName: 'quest',
          url: window.location.href,
          walletAddress: walletAddress,
        })
      } catch (error) {
        console.error('Error updating Telegram verification:', error)
      }
    }, 2000)
  }

  const awardPointsForTelegram = async () => {
    const pointsData = {
      walletAddress,
      points: 100,
      type: 'telegram_verification',
      note: '',
    }
    const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')

    try {
      const result = await createAndUpdatePoints(pointsData)
    } catch (error) {
      console.error('Failed to award points for Telegram verification:', error)
    }
  }

  // Function to validate the referral code
  const validateReferralCode = async (code) => {
    const validateFunction = httpsCallable(functions, 'validateReferralCode')

    try {
      const result = await validateFunction({ referralCode: code })
      if (result.data.isValid) {
        console.log('Referral code is valid:', result.data.referralCodeDetails)
        return true
      } else {
        console.error('Invalid referral code. Please try again.')
        return false
      }
    } catch (error) {
      console.error('Error validating referral code:', error)
    }
  }

  // Function to process the referral code
  const processReferralCode = async (code, wallet) => {
    const processFunction = httpsCallable(functions, 'processReferral')
    try {
      console.log('Processing referral code:', code, 'for wallet:', wallet)
      await processFunction({ newUserWalletAddress: wallet, referralCode: code })
    } catch (error) {
      console.error('Error processing referral code:', error)
    }
  }

  const awardPointsForReferral = async () => {
    const pointsData = {
      walletAddress,
      points: 50,
      type: 'redeem_referral_code',
      note: '',
    }
    const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')

    try {
      const result = await createAndUpdatePoints(pointsData)
      console.log('Points successfully awarded:', result)
    } catch (error) {
      console.error('Failed to award points:', error)
    }
  }

  const handleReferralCodeSubmit = async () => {
    if (!referralCode) {
      setReferralCodeFeedback('Enter an invite code')
      return
    }
    setReferralCodeFeedback('') // Reset feedback message
    setIsVerifyingReferralCode(true) // Indicate loading state
    const isValid = await validateReferralCode(referralCode)
    if (isValid) {
      await processReferralCode(referralCode, walletAddress)
      await awardReferralCodeUsedPoints(functions, walletAddress, referralCode)
      setIsReferralCodeValid(true)
      await awardPointsForReferral()

      Amplitude.getInstance().logEvent('referral code redeemed', {
        pageName: 'quest',
        referralCode: referralCode,
        walletAddress: walletAddress,
        url: window.location.href,
      })
    } else {
      setReferralCodeFeedback('Invalid invite code')
      setIsVerifyingReferralCode(false) // Reset loading state
    }
  }

  const connectToTwitterWithPrivy = async () => {
    // Trigger Twitter login with Privy
    login()
  }

  // This function is used both for users that connect with Privy and for users who manually enter their twitter handle
  const verifyTwitterConnect = async () => {
    setIsVerifyingTwitterConnect(true)
    setTwitterConnectFeedback('') // Reset in case user previously tried to enter a used twitter username

    // When isAndroidMobile is true, the button communicates the processing state
    if (user?.twitter) {
      setTwitterConnectFeedback('Verifying...')
    }

    if (user?.twitter || twitterHandle) {
      // Control for either Privy connected twitter, or manually entered twitter handle
      const twitterUsername = user?.twitter?.username || twitterHandle
      const twitterData = {
        name: user?.twitter?.name || null,
        profilePictureUrl: user?.twitter?.profilePictureUrl || null,
        subject: user?.twitter?.subject || null,
        username: twitterUsername,
      }

      // Call to check if this twitter account is associated with another wallet
      const checkTwitterAccountAssociation = httpsCallable(functions, 'checkTwitterAccountAssociation')
      try {
        const associationResult = await checkTwitterAccountAssociation({
          twitterUsername: twitterUsername, // converted to lowercase serverside
          currentUserWalletAddress: walletAddress,
        })

        if (associationResult.data.isAssociatedWithAnotherWallet) {
          setIsVerifyingTwitterConnect(false)
          setTwitterConnectFeedback('Twitter account already in use.')
          logout() // Logout of twitter, if Privy was connected

          return
        }

        // If not, proceed with verification
        const updateTwitterConnectVerification = httpsCallable(functions, 'updateTwitterConnectVerification')
        await updateTwitterConnectVerification({
          walletAddress,
          isTwitterConnectVerified: true,
          twitterData: twitterData,
        })
        console.log('Twitter connect verification updated.')
        setIsTwitterConnectVerified(true)
        awardPointsForTwitterConnect()

        Amplitude.getInstance().logEvent('twitter connected', {
          pageName: 'quest',
          twitterUsername: twitterUsername.toLowerCase(),
          walletAddress: walletAddress,
          url: window.location.href,
        })
      } catch (error) {
        console.error('Error during Twitter connection verification:', error)
        setTwitterConnectFeedback('Error verifying Twitter connection.')
        setIsVerifyingTwitterConnect(false)
      }
    } else {
      setTwitterConnectFeedback('Please connect to Twitter')
      setIsVerifyingTwitterConnect(false)
      return
    }
  }

  return (
    <QuestContainer>
      <Step>
        <StyledNumberOuterContainer>
          <StyledNumberContainer>
            <StyledNumber>1</StyledNumber>
          </StyledNumberContainer>
        </StyledNumberOuterContainer>
        <InnerStepContainer>
          <StepInfo>
            <PointsContainer>
              <StepSubtitle>50 points</StepSubtitle>
            </PointsContainer>
            <StepTitle>Redeem invite code.</StepTitle>
            <StepTip>
              Don't have a code? Ask for one in{' '}
              <Link href="https://t.me/trulybasedchat" target="_blank" rel="noopener noreferrer">
                Telegram
              </Link>
              .
            </StepTip>
          </StepInfo>
          {userProgress?.referralCodeRedeemedAt ? (
            <img src={CheckBadge} />
          ) : (
            <ButtonSpace>
              <Input
                type="text"
                value={referralCode}
                onChange={(e) => {
                  if (e.target.value.length <= 12) {
                    setReferralCode(e.target.value)
                  }
                }}
                placeholder="Enter code"
                disabled={isVerifyingReferralCode}
              />
              <SubmitSpace>
                <Button
                  onClick={handleReferralCodeSubmit}
                  disabled={isVerifyingReferralCode}
                  isVerifying={isVerifyingReferralCode}
                >
                  {isVerifyingReferralCode ? 'Validating...' : 'Submit'}
                </Button>
                <VerificationSpace customStyle={tw`top-[82px] md:top-[55px]`}>
                  <VerificationMessage>{referralCodeFeedback}</VerificationMessage>
                </VerificationSpace>
              </SubmitSpace>
            </ButtonSpace>
          )}
        </InnerStepContainer>
      </Step>

      <Divider customStyle={tw`w-full mx-0 my-12 border-gray-500`} />

      <Step>
        <StyledNumberOuterContainer>
          <StyledNumberContainer>
            <StyledNumber>2</StyledNumber>
          </StyledNumberContainer>
        </StyledNumberOuterContainer>
        <InnerStepContainer>
          <StepInfo>
            <PointsContainer>
              <StepSubtitle>70 points</StepSubtitle>
            </PointsContainer>
            <StepTitle>{isAndroidMobile ? 'Add your Twitter username.' : 'Connect your Twitter account.'}</StepTitle>
            {isAndroidMobile && (
              <StepTip>Make sure this is correct! Otherwise you won't be able to complete sign up.</StepTip>
            )}
          </StepInfo>
          {userProgress?.isTwitterConnectVerified || isTwitterConnectVerified ? ( // We look at both local state and userProgress to determine the state
            <img src={CheckBadge} />
          ) : (
            // If isAndroidMobile is true, we ask the user to input their twitter handle.
            // Twitter OAuth for android mobile is broken
            <>
              {isAndroidMobile ? (
                <ButtonSpace>
                  <Input
                    type="text"
                    value={twitterHandle}
                    onChange={(e) => {
                      if (e.target.value.length <= 15) {
                        setTwitterHandle(e.target.value)
                      }
                    }}
                    placeholder="Username"
                    disabled={isVerifyingTwitterConnect}
                  />
                  <SubmitSpace>
                    <Button
                      onClick={() => {
                        verifyTwitterConnect()
                      }}
                      disabled={
                        isVerifyingTwitterConnect || disablePrivyConnection || !isReferralCodeValid || isRetweetVerified
                      } // Disabled until Privy is ready and referral code is valid and verificaiton is not already in progress
                      isVerifying={isVerifyingTwitterConnect}
                    >
                      {isVerifyingTwitterConnect ? 'Validating...' : 'Submit'}
                    </Button>
                    <VerificationSpace customStyle={tw`top-[82px] md:top-[55px]`}>
                      <VerificationMessage>{twitterConnectFeedback}</VerificationMessage>
                    </VerificationSpace>
                  </SubmitSpace>
                </ButtonSpace>
              ) : (
                <ButtonSpace>
                  <SubmitSpace>
                    <Button
                      disabled={disablePrivyConnection || !isReferralCodeValid || isRetweetVerified} // Disabled until Privy is ready and referral code is valid
                      onClick={() => {
                        connectToTwitterWithPrivy()
                      }}
                    >
                      Connect
                    </Button>
                    <VerificationSpace>
                      <VerificationMessage
                        as="button"
                        disabled={!isTwitterConnectAttempted || isVerifyingTwitterConnect || isTwitterConnectVerified}
                        onClick={
                          isTwitterConnectAttempted && !isVerifyingTwitterConnect && !isTwitterConnectVerified
                            ? verifyTwitterConnect
                            : undefined
                        }
                      >
                        {!isVerifyingTwitterConnect && !isTwitterConnectVerified && isTwitterConnectAttempted && (
                          <Arrow animate={true} />
                        )}
                        {twitterConnectFeedback}
                      </VerificationMessage>
                    </VerificationSpace>
                  </SubmitSpace>
                </ButtonSpace>
              )}
            </>
          )}
        </InnerStepContainer>
      </Step>

      <Divider customStyle={tw`w-full mx-0 my-12 border-gray-500`} />

      <Step>
        <StyledNumberOuterContainer>
          <StyledNumberContainer>
            <StyledNumber>3</StyledNumber>
          </StyledNumberContainer>
        </StyledNumberOuterContainer>
        <InnerStepContainer>
          <StepInfo>
            <PointsContainer>
              <StepSubtitle>100 points</StepSubtitle>
              <Tooltip>
                <span>
                  Your retweet must be live at the time of the points snapshot for the points awarded to be valid.
                </span>
              </Tooltip>
            </PointsContainer>
            <StepTitle>Retweet Truly launch post.</StepTitle>
          </StepInfo>
          {userProgress?.isRetweetVerified || isRetweetVerified ? ( // We look at both local state and userProgress to determine the state
            <img src={CheckBadge} />
          ) : (
            <ButtonSpace>
              <SubmitSpace>
                <Button
                  onClick={() => {
                    setIsRetweetAttempted(true)
                    setRetweetFeedbackState('')
                    setRetweetFeedback('Click to verify')

                    if (isMobile) {
                      // Open directly to the tweet for mobile users
                      window.open('https://twitter.com/trulyhq/status/1783212659627720813', '_blank')
                      successStepsForRetweetVerified()
                    } else {
                      // Use the retweet intent for desktop users
                      window.open(
                        'https://twitter.com/intent/retweet?tweet_id=1783212659627720813',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  }}
                  disabled={!userProgress?.isTwitterConnectVerified || !isTwitterConnectVerified} // Disabled until twitter is connected
                >
                  Retweet
                </Button>
                <VerificationSpace>
                  <VerificationMessage
                    as="button"
                    disabled={
                      !isRetweetAttempted || isVerifyingRetweet || isRetweetVerified || retweetFeedbackState == 'error'
                    }
                    onClick={
                      isRetweetAttempted && !isVerifyingRetweet && !isRetweetVerified ? verifyRetweet : undefined
                    }
                  >
                    {!isVerifyingRetweet &&
                      !isRetweetVerified &&
                      isRetweetAttempted &&
                      retweetFeedbackState != 'error' && <Arrow animate={true} />}
                    {retweetFeedback}
                  </VerificationMessage>
                </VerificationSpace>
              </SubmitSpace>
            </ButtonSpace>
          )}
        </InnerStepContainer>
      </Step>

      <Divider customStyle={tw`w-full mx-0 my-12 border-gray-500`} />

      <Step>
        <StyledNumberOuterContainer>
          <StyledNumberContainer>
            <StyledNumber>4</StyledNumber>
          </StyledNumberContainer>
        </StyledNumberOuterContainer>
        <InnerStepContainer>
          <StepInfo>
            <PointsContainer>
              <StepSubtitle>100 points</StepSubtitle>
              <Tooltip>
                <span>
                  You must be actively following Truly on Twitter at the time of the points snapshot for the points
                  awarded to be valid.
                </span>
              </Tooltip>
            </PointsContainer>
            <StepTitle>Follow @TrulyHQ on Twitter.</StepTitle>
          </StepInfo>
          {userProgress?.isTwitterFollowVerified || isTwitterFollowVerified ? ( // We look at both local state and userProgress to determine the state
            <img src={CheckBadge} />
          ) : (
            <ButtonSpace>
              <SubmitSpace>
                <Button
                  onClick={() => {
                    setIsTwitterAttempted(true) // Track that the follow attempt was made

                    if (isMobile) {
                      // Open directly to the Truly account for mobile users
                      window.open('https://twitter.com/trulyhq/', '_blank')
                      verifyTwitterFollow()
                    } else {
                      // Use the follow intent for desktop users
                      window.open(
                        'https://twitter.com/intent/follow?screen_name=trulyhq',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  }}
                  disabled={!isRetweetVerified || isTwitterFollowVerified} // Disabled until retweet is verified
                >
                  Follow
                </Button>
                <VerificationSpace>
                  <VerificationMessage
                    as="button"
                    disabled={!isTwitterAttempted || isVerifyingTwitter || isTwitterFollowVerified}
                    onClick={
                      isTwitterAttempted && !isVerifyingTwitter && !isTwitterFollowVerified
                        ? verifyTwitterFollow
                        : undefined
                    }
                  >
                    {!isVerifyingTwitter && !isTwitterFollowVerified && isTwitterAttempted && <Arrow animate={true} />}
                    {isVerifyingTwitter ? 'Verifying...' : 'Click to verify'}
                  </VerificationMessage>
                </VerificationSpace>
              </SubmitSpace>
            </ButtonSpace>
          )}
        </InnerStepContainer>
      </Step>

      <Divider customStyle={tw`w-full mx-0 my-12 border-gray-500`} />

      <Step>
        <StyledNumberOuterContainer>
          <StyledNumberContainer>
            <StyledNumber>5</StyledNumber>
          </StyledNumberContainer>
        </StyledNumberOuterContainer>
        <InnerStepContainer>
          <StepInfo>
            <PointsContainer>
              <StepSubtitle>100 points</StepSubtitle>
              <Tooltip>
                <span>
                  You must be an active member of the Truly Telegram at the time of the points snapshot for the points
                  awarded to be valid.
                </span>
              </Tooltip>
            </PointsContainer>
            <StepTitle>Join the Truly Telegram.</StepTitle>
          </StepInfo>
          {userProgress?.isTelegramVerified || isTelegramVerified ? ( // We look at both local state and userProgress to determine the state
            <img src={CheckBadge} />
          ) : (
            <ButtonSpace>
              <SubmitSpace>
                <Button
                  href="https://t.me/trulybasedchat"
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={!isTwitterFollowVerified || isTelegramVerified} // Disabled until Twitter is verified
                  onClick={() => {
                    setIsTelegramAttempted(true)
                    window.open('https://t.me/trulybasedchat', '_blank', 'noopener,noreferrer')
                  }}
                >
                  Join TG
                </Button>
                <VerificationSpace>
                  <VerificationMessage
                    as="button"
                    disabled={!isTelegramAttempted || isVerifyingTelegram || isTelegramVerified}
                    onClick={
                      isTelegramAttempted && !isVerifyingTelegram && !isTelegramVerified
                        ? verifyTelegramJoin
                        : undefined
                    }
                  >
                    {!isVerifyingTelegram && !isTelegramVerified && isTelegramAttempted && <Arrow animate={true} />}
                    {isVerifyingTelegram ? 'Verifying...' : 'Click to verify'}
                  </VerificationMessage>
                </VerificationSpace>
              </SubmitSpace>
            </ButtonSpace>
          )}
        </InnerStepContainer>
      </Step>

      <Divider customStyle={tw`w-full mx-0 my-12 border-gray-500`} />

      {isSubmitting && <ProcessingModal isOpen={true} text={'Processing'} />}
    </QuestContainer>
  )
}
