import React, { useContext } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import Modal from 'react-modal'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import './App.css'
import 'tailwindcss/dist/base.css'
import 'styles/globalStyles.css'

import MarketingHome from './pages/marketingHome'
import USHToken from './pages/ushToken'
import Contact from './pages/contact'
import LoadingPage from './pages/loading'
import Restricted from './pages/restricted'
import Points from './pages/points'
import Terms from './pages/terms'
import firebaseConfig from 'config/firebase'
import privyConfig from 'config/privy'
import amplitudeConfig from 'config/amplitude'

import amplitude from 'amplitude-js'

import CountryBar from 'components/headers/CountryBar'
import ModalShell from 'components/modals/ModalShell'
import { ModalProvider } from './contexts/ModalContext'
import { LocationProvider, LocationContext } from './contexts/LocationContext'
import { WalletProvider } from './contexts/WalletContext'
import { FingerprintProvider } from 'contexts/FingerprintContext'
import { PrivyProvider } from '@privy-io/react-auth'

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firestore
const db = getFirestore(app)
export { db }

// Initialize and export Firebase Functions
export const functions = getFunctions(app)

// Initialize Analytics
if (process.env.NODE_ENV === 'production') {
  const analytics = getAnalytics(app)
}

amplitude.getInstance().init(amplitudeConfig.apiKey)

Modal.setAppElement('#root')

function App() {
  return (
    <BrowserRouter>
      <PrivyProvider
        appId={privyConfig.appId}
        config={{
          // Display email and wallet as login methods
          loginMethods: ['twitter'],
          // Customize Privy's appearance in your app
          appearance: {
            theme: 'light',
            accentColor: '#36b4ac',
          },
        }}
      >
        <FingerprintProvider>
          <WalletProvider>
            <ModalProvider>
              <LocationProvider>
                <AppContent />
              </LocationProvider>
            </ModalProvider>
          </WalletProvider>
        </FingerprintProvider>
      </PrivyProvider>
    </BrowserRouter>
  )
}

const PointsPage = () => {
  // const { isInUS } = useContext(LocationContext)

  return (
    <>
      {/* {isInUS && <CountryBar />} */}
      <Points />
    </>
  )
}

// const RestrictedPage = () => {
//   // const { isInUS } = useContext(LocationContext)

//   return (
//     <>
//       {/* {isInUS && <CountryBar />} */}
//       <Restricted />
//     </>
//   )
// }

const AppContent = () => {
  const { isInUS } = useContext(LocationContext)

  // Handle the loading state or undetermined state
  if (isInUS === null) {
    return <LoadingPage /> // Show a loading or default page while location is being determined
  }

  return (
    <>
      {isInUS && <CountryBar />}
      <ModalShell />
      <Routes>
        <Route path="/" element={<MarketingHome />} />
        <Route path="/ush" element={<USHToken />} />
        <Route path="/contact" element={<Contact />} />
        {/* Using wrapper component to conditionally render CountryBar */}
        <Route path="/points" element={<PointsPage />} />
        {/* Using wrapper component to conditionally render CountryBar */}
        {/* <Route path="/restricted" element={<RestrictedPage />} /> */}
        <Route path="/terms" element={<Terms />} />
        {/* Define other routes here, which might be common to all users */}
        {!isInUS && <></>}
        {/* Redirect to homepage if the route does not match any of the above */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  )
}

export default App
