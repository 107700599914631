import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import styled from 'styled-components'
import { LocationContext } from '../contexts/LocationContext'

import TwoCol from 'components/features/TwoCol'
import TwoColSingleFeatureWithStats2 from 'components/features/TwoColSingleFeatureWithStats2'
import TwoColumnWithInput from '../components/hero/TwoColumnWithInput.js'
import HorizontalCarousel from '../components/carousels/HorizontalCarousel.js'
import StaticBanner from 'components/misc/StaticBanner.js'
import TwoColWithButton from 'components/features/TwoColWithButton.js'
import SimpleFiveColumn from 'components/footers/SimpleFiveColumn.js'
import TwoColWithSteps from '../components/features/TwoColWithSteps.js'
import { Divider } from 'components/misc/Divider'

import Amplitude from 'amplitude-js'

import signature from '../assets/misc/signature.png'
import founderPic from '../assets/teamPhotos/founderPic.webp'

import fiveYearReturns from 'assets/infographics/fiveYearReturns.webp'

import saludaGrade from '../assets/partnerLogos/saludaGrade.svg'
import bainCapital from '../assets/partnerLogos/bainCapital.png'
import baird from '../assets/partnerLogos/baird.png'
import barclays from '../assets/partnerLogos/barclays.png'
import cantorFitzgerald from '../assets/partnerLogos/cantorFitzgerald.png'
import jeffries from '../assets/partnerLogos/jefferies.svg'
import nomura from '../assets/partnerLogos/nomura.png'
import redwoodTrust from '../assets/partnerLogos/redwoodTrust.png'
import alliance from '../assets/investorLogos/alliance.svg'

// Lottie files
import map from '../assets/lottie/map.json'
import barGraph from '../assets/lottie/barGraph.json'
import lineGraph from '../assets/lottie/lineGraph.json'
import underwriting from '../assets/lottie/underwriting.json'
import backtestedPerformance from '../assets/lottie/backtestedPerformance.json'

const HighlightedText = styled.span`
  ${tw`font-medium`}
`
const VeryHighlightedText = styled.span`
  ${tw`font-semibold`}
`
const Container = tw.div`pt-0 pb-8 px-12 px-4 md:px-8 bg-bone min-h-screen overflow-hidden font-display`

export default () => {
  const { isInUS } = useContext(LocationContext)
  const navigate = useNavigate()

  Amplitude.getInstance().logEvent('page viewed', {
    pageName: 'home',
    url: window.location.href,
    referrerUrl: document.referrer,
  })

  const userType = isInUS ? 'us-retail' : 'global-retail'

  const investors = [{ src: alliance, alt: 'Alliance logo' }]

  const logos = [
    { src: saludaGrade, alt: 'Saluda Grade logo' },
    { src: bainCapital, alt: 'Bain Capital logo' },
    { src: baird, alt: 'Robert W. Baird logo' },
    { src: barclays, alt: 'Barclays logo' },
    { src: cantorFitzgerald, alt: 'Cantor Fitzgerald logo' },
    { src: jeffries, alt: 'Jeffries logo' },
    { src: nomura, alt: 'Nomura logo' },
    { src: redwoodTrust, alt: 'Redwood Trust logo' },
  ]

  const stepsIntl = [
    {
      heading: 'Simple',
      description: 'Diversified exposure to U.S. residential real estate. No rental operation risk.',
    },
    { heading: 'Liquid', description: 'Sell your position at any time with no lockups or transfer restrictions.' },
    { heading: 'Composable', description: 'ERC-20 tokens are self-custodied and fully composable with all of DeFi.' },
  ]
  const stepsUS = [
    {
      heading: 'Simple',
      description: 'Diversified exposure to U.S. residential real estate. No rental operation risk.',
    },
    { heading: 'Liquid', description: 'Buy and sell at any time using your favorite defi apps.' },
    { heading: 'Tax Advantaged', description: 'No K1s. Just capital gains when you sell.' },
  ]

  const stats = [
    {
      key: 'Data points per home',
      value: '170+',
    },
    {
      key: 'Allowed geographies',
      value: '392',
    },
    {
      key: 'Downside protection',
      value: '~50%',
    },
  ]

  return (
    <Container>
      <TwoColumnWithInput
        title={<>Real estate investing transformed</>}
        heading={
          <>
            American real estate, <br />
            <HighlightedText>Permissionless.</HighlightedText>
          </>
        }
        subheading={
          isInUS ? (
            <>
              Truly is a real estate investment protocol. We put U.S. real estate on a{' '}
              <VeryHighlightedText>permissionless token</VeryHighlightedText>, and make it{' '}
              <VeryHighlightedText>globally accessible.</VeryHighlightedText>
            </>
          ) : (
            <>
              Truly is a real estate investment protocol. We put U.S. real estate on a{' '}
              <VeryHighlightedText>permissionless token</VeryHighlightedText>, and return{' '}
              <VeryHighlightedText>13% APY.</VeryHighlightedText>
            </>
          )
        }
        roundedHeaderButton={true}
        showButton={true}
        buttonText={'Join Points Program ➤'}
        buttonOnClick={() => {
          navigate('/points')
          Amplitude.getInstance().logEvent('button clicked', {
            buttonName: 'join points program',
            pageName: 'home',
            url: window.location.href,
          })
        }}
        userType={userType}
        form={false}
        lottie={map}
        mode={'light'}
      />

      <StaticBanner title={<>Backed By</>} logos={investors} />

      <TwoCol
        title={"Backed by the world's best assets"}
        lottie={barGraph}
        blockImage={isInUS}
        imageTitle={'Annual Return Comparison'}
        graphMode={true}
        textOnLeft={false}
        heading={
          <>
            Simple and secure, with <HighlightedText>unbeatable returns.</HighlightedText>
          </>
        }
        description={
          <>
            The Truly protocol acquires home equity investments (HEIs) and issues the asset-backed token USH. With USH,
            investors can enjoy stable, secured exposure to U.S. owner-occupied properties.
          </>
        }
        showButton={true}
        buttonText={'Learn about USH ➤'}
        buttonOnclick={() => {
          window.location.href = '/ush'
          Amplitude.getInstance().logEvent('button clicked', {
            buttonName: 'learn about ush',
            pageName: 'home',
            url: window.location.href,
          })
        }}
      />

      <HorizontalCarousel title={<>Assets trusted by leading institutions</>} logos={logos} />

      <TwoColWithSteps
        steps={isInUS ? stepsUS : stepsIntl}
        subheading={'Best way to invest in U.S. real estate'}
        lottie={lineGraph}
        blockImage={isInUS}
        imageSrc={fiveYearReturns}
        imageTitle={'Five-Year Return Comparison'}
        graphMode={true}
        textOnLeft={true}
        showButton={false}
        heading={
          isInUS ? (
            <>
              U.S. residential real estate investing <HighlightedText>made easy.</HighlightedText>
            </>
          ) : (
            <>
              Invest in U.S. homes with the <HighlightedText>USH token.</HighlightedText>
            </>
          )
        }
      />

      <Divider customStyle={tw`lg:mt-8`} />

      <TwoColSingleFeatureWithStats2
        title="High-quality real estate"
        heading={
          <>
            Thorough underwriting for <HighlightedText>every asset.</HighlightedText>
          </>
        }
        description="Truly runs each real estate asset through bank-grade underwriting and price modeling. Our strict standards ensure only the best real estate assets back USH. "
        lottie={underwriting}
        lottieContainerStyle={{ padding: '0 4rem' }}
        textOnLeft={false}
        statistics={stats}
        buttonText="Read the docs ➤"
        buttonOnclick={() => {
          window.open('https://docs.trulybased.com', '_blank')
          Amplitude.getInstance().logEvent('button clicked', {
            buttonName: 'read the docs',
            pageName: 'home',
            url: window.location.href,
          })
        }}
      />

      <Divider customStyle={tw``} />

      <TwoCol
        title="Resilient returns"
        heading={
          <>
            Reliable performance, <HighlightedText>even in turbulent markets.</HighlightedText>
          </>
        }
        description="Truly deploys capital to HEIs in a sophisticated investment strategy. USH holders benefit from this exposure with reliably high returns — largely irrespective of housing market volatility."
        lottie={backtestedPerformance}
        blockImage={isInUS}
        graphMode={true}
        imageTitle={'Backtested Performance'}
        textOnLeft={true}
        showButton={false}
      />

      <TwoColWithButton
        mode={'dark'}
        subheading={'Meet the founders'}
        heading={
          <>
            We're in it for <HighlightedText>YOU.</HighlightedText>
          </>
        }
        description={
          <>
            Hi. My name’s Nathan.
            <br />
            <br />
            Back in 2014, I co-founded one of the largest mortgage technology companies in the world. We originated,
            underwrote, and processed $600B per year in U.S. mortgages for banks like Chase, HSBC, and Santander.
            <br />
            <br />
            Working alongside these giant financial institutions, Val and I gained institutional expertise in real
            estate investing, asset securitization, and tax-efficient structuring. Now we're ready to share some
            secrets.
            <br />
            <br />
            When we started Truly, our goal was to create the world’s best real estate investment. Period. On-chain or
            off-chain, you won’t find better alpha or a more nimble product. We’re here to help you grow your wealth and
            secure your future.
            <br />
            <br />
            <VeryHighlightedText>We’re in it for YOU.</VeryHighlightedText>
          </>
        }
        showButton={false}
        signatureSrc={signature}
        imageSrc={founderPic}
      />
      <SimpleFiveColumn />
    </Container>
  )
}
