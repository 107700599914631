import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import Lottie from 'lottie-react' // Updated import for lottie-react

import Header from '../headers/light.js'
import EmailCaptureWithForm from '../forms/emailCaptureWithForm.js'

import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24 gap-0`
const LeftColumn = tw.div`relative lg:w-6/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`
const RightColumn = tw.div`sm:hidden md:hidden relative mt-6 lg:mt-0 flex-1 lg:flex flex-col justify-center lg:self-start md:mr--4 `

const Title = tw.p`font-bold font-sans tracking-widest word-spacing-wide uppercase text-turquoise text-sm md:text-base mb-8`
const Heading = tw.h1`font-light font-serif text-4xl md:text-5xl xl:text-6xl text-tertiary-700 leading-tight`
const Subheading = tw.p`font-light font-sans text-blue-500 my-5 lg:my-8 text-xl xl:text-2xl sm:pb-2`
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`

const MobileImageContainer = tw.div`pt-10 pb-2 2xl:hidden xl:hidden lg:hidden`

const Image = styled.img((props) => [tw`min-w-0 w-full max-w-lg xl:max-w-3xl rounded-lg`])

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`

const PrimaryButton = tw(PrimaryButtonBase)`z-50 mt-4 text-sm inline-block md:mx-0 max-w-64 text-center`

export default ({
  roundedHeaderButton,
  form,
  modalType,
  userType,
  title,
  heading,
  subheading,
  imageSrc,
  mode,
  buttonLabel,
  lottie,
  twitter = false,
  showButton = false,
  buttonText = 'Get Started',
  buttonOnClick = () => {},
}) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} mode={mode} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Title css={[mode == 'dark' && tw`text-white`]}>{title}</Title>
            <Heading css={[mode == 'dark' && tw`text-white`]}>{heading}</Heading>
            <MobileImageContainer>
              {lottie ? (
                <div style={{ pointerEvents: 'none' }}>
                  <Lottie animationData={lottie} loop autoplay style={{ width: '100%', height: 'auto' }} />
                </div>
              ) : (
                <IllustrationContainer>
                  <Image
                    css={[mode == 'dark' ? tw`shadow-glow-lgAqua` : tw`shadow-glow-smGray`]}
                    src={imageSrc}
                    alt="Design Illustration"
                  />
                </IllustrationContainer>
              )}
            </MobileImageContainer>
            <Subheading css={[mode == 'dark' && tw`text-white`]}>{subheading}</Subheading>
            {showButton && (
              <PrimaryButton as="a" onClick={buttonOnClick}>
                {buttonText}
              </PrimaryButton>
            )}
            {form && (
              <EmailCaptureWithForm mode={mode} userType={userType} modalType={modalType} buttonLabel={buttonLabel} />
            )}
          </LeftColumn>
          <RightColumn>
            {lottie ? (
              <div style={{ pointerEvents: 'none' }}>
                <Lottie animationData={lottie} loop={true} autoplay={true} style={{ width: '100%', height: 'auto' }} />
              </div>
            ) : (
              <IllustrationContainer>
                <Image
                  css={[mode == 'dark' ? tw`shadow-glow-lgAqua` : tw`shadow-glow-smGray`]}
                  src={imageSrc}
                  alt="Design Illustration"
                />
              </IllustrationContainer>
            )}
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  )
}
