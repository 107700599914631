let amplitudeConfig = {}

// TODO: Move API key to env variable. Is this important?
if (process.env.NODE_ENV === 'development') {
  amplitudeConfig = {
    apiKey: '2768f12519253dcc20d07c227aedbc7e',
  }
} else {
  amplitudeConfig = {
    apiKey: 'cad259af27736148aff598cfcf5522bc',
  }
}

module.exports = amplitudeConfig
