import React from 'react'
import styled, { keyframes } from 'styled-components'

// Define the spin animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

// Use styled-components to create the spinner
const Loader = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
  border-top-color: #fff; /* White */
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`

// Spinner component
export const Spinner = () => <Loader />

export default Spinner
