import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import tw from 'twin.macro'

const ModalFooter = styled.div`
  ${tw`absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-b from-transparent to-white pointer-events-none block`}
`

const MyModalComponent = ({ className, children, ...props }) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`

  return (
    <Modal className={contentClassName} overlayClassName={overlayClassName} {...props}>
      {children}
      <ModalFooter />
    </Modal>
  )
}

export default MyModalComponent
