import React, { useContext } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import Amplitude from 'amplitude-js'

import logoLight from '../../assets/logos/logoLight.svg'
import { FaTwitter } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa'
import { LocationContext } from 'contexts/LocationContext'
import { ReactComponent as SvgDecoratorBlob1 } from 'assets/svg-decorator-blob-9.svg'
import getDocsendLink from 'config/docsend'

const Container = tw.div`relative bg-gray-1000 -mx-12 -mb-8 px-12 py-16 lg:py-20 z-0 border-t-2 border-gray-600 `
const TwoColumns = tw.div`max-w-screen-xl mx-auto flex flex-wrap justify-between sm:justify-center z-10 relative pb-12`

const SmallColumn = tw.div`md:w-1/4 text-white text-right`
const Column = tw.div`md:w-1/3 text-white text-left`
const WideColumn = tw(Column)`text-center md:text-right w-full md:w-2/3 mb-10 md:mb-0 flex-row justify-end`
const Row = tw.div`max-w-screen-xl mx-auto w-full text-left z-50 relative`

const LinkList = tw.ul`mt-6 text-sm font-medium font-sans text-white text-sm`
const LinkListItem = tw.li`inline-block mx-4 mt-4 md:mt-8`
const Link = tw.a`border-b-2 border-transparent hocus:text-aqua-500 hocus:border-aqua-500 pb-1 transition duration-300`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-48`

const CompanyDescription = tw.p`font-sans mt-6 max-w-xs font-normal text-base mx-auto md:mx-0 md:mr-4 text-white text-left sm:text-center`
const Copyright = tw.p`font-sans mt-4 max-w-xs font-light text-sm mx-auto md:mx-0 md:mr-4 text-gray-100 text-left sm:text-center`
const Disclosure = styled.p`
  ${tw`font-sans font-light text-sm text-gray-100 text-justify`}
`

const SocialLinksContainer = tw.div`mt-4 flex justify-center md:justify-start`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-1000 text-white hover:bg-turquoise transition duration-300 mr-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg z-0`
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-gray-800 opacity-50`
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-gray-800 opacity-50`

export default () => {
  const linkItems = [
    { href: '/ush', title: 'USH Token' },
    { href: 'https://docs.trulybased.com', title: 'Docs', isExternal: true },
    { href: 'https://blog.trulybased.com', title: 'Blog', isExternal: true },
    { href: '/contact', title: 'Contact' },
    { href: '/terms', title: 'Terms' },
  ]

  return (
    <Container>
      <TwoColumns>
        <Column>
          <LogoContainer>
            <LogoImg src={logoLight} />
          </LogoContainer>

          <CompanyDescription>
            Truly is a real estate investment protocol that puts a portfolio of US homes on a permissionless token.
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink
              href="https://twitter.com/trulyhq"
              target="_blank"
              rel="noopener noreferrer"
              onclick={() => {
                Amplitude.getInstance().logEvent('social link clicked', {
                  buttonName: 'twitter',
                  pageName: 'footer',
                  url: window.location.href,
                })
              }}
            >
              <FaTwitter />
            </SocialLink>

            <SocialLink
              href="https://t.me/trulybasedchat"
              target="_blank"
              rel="noopener noreferrer"
              onclick={() => {
                Amplitude.getInstance().logEvent('social link clicked', {
                  buttonName: 'telegram',
                  pageName: 'footer',
                  url: window.location.href,
                })
              }}
            >
              <FaTelegramPlane />
            </SocialLink>
          </SocialLinksContainer>
          <Copyright>&copy; 2024 Villcaso Corporation. All Rights Reserved.</Copyright>
        </Column>
        <WideColumn>
          <LinkList>
            {linkItems.map((link, index) => (
              <LinkListItem key={index}>
                <Link
                  href={link.href}
                  target={link.isExternal ? '_blank' : '_self'}
                  rel={link.isExternal ? 'noopener noreferrer' : undefined}
                >
                  {link.title}
                </Link>
              </LinkListItem>
            ))}
          </LinkList>
        </WideColumn>
      </TwoColumns>
      <Row>
        <Disclosure>
          Please note that the information provided on this site is for educational purposes only and does not
          constitute an offer to buy or sell securities. We are not a broker-dealer or an investment advisor and do not
          provide investment advice. Any investment in real estate involves risks, and potential investors should seek
          professional advice before making any investment decisions. This site is intended to provide general
          information about investing in US real estate and should not be relied upon as a substitute for professional
          advice. By accessing this site, you acknowledge that you have read and understood this disclaimer.
        </Disclosure>
      </Row>
    </Container>
  )
}
