import React, { useEffect, useState } from 'react'
import { useWallet } from '../contexts/WalletContext.js'
import tw from 'twin.macro'
import styled from 'styled-components'
import { httpsCallable } from 'firebase/functions'

import { functions } from '../App'
import SimpleFiveColumn from '../components/footers/SimpleFiveColumn.js'
import { Quest } from '../components/points/Quest.js'
import { PointsHome } from '../components/points/PointsHome.js'
import JustText from 'components/hero/JustText.js'
import PointsCampaign from 'components/points/PointsCampaign.js'

import Amplitude from 'amplitude-js'

const Container = tw.div`pt-0 pb-8 px-4 md:px-8 bg-gray-900 min-h-screen overflow-hidden font-display`
const HighlightedText = styled.span`
  ${tw`font-medium`}
`
const VeryHighlightedText = styled.span`
  ${tw`font-semibold`}
`
const BonusContentContainer = styled.div`
  ${tw`w-auto md:max-w-[75%] mb-2`}
`

const Points = () => {
  Amplitude.getInstance().logEvent('page viewed', {
    pageName: 'points',
    url: window.location.href,
    referrerUrl: document.referrer,
  })

  const { walletAddress, userProgress, connectWallet } = useWallet()
  const [seasonDetails, setSeasonDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  // Determine if the device is mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  const getSeasonDetails = httpsCallable(functions, 'getSeasonDetails')

  useEffect(() => {
    // Attempt to load season details from local storage
    const localData = localStorage.getItem('seasonDetails')
    if (localData) {
      setSeasonDetails(JSON.parse(localData))
      setIsLoading(false) // Set loading to false if data is found
    }

    // Always fetch fresh season details
    console.log('Fetching season details...')
    setIsLoading(true)
    getSeasonDetails({ number: 2 }) // Modify this based on active season
      .then((result) => {
        if (result.data.success) {
          const { data } = result.data
          console.log('Season details fetched:', data)
          localStorage.setItem('seasonDetails', JSON.stringify(data)) // Update local storage
          setSeasonDetails(data) // Update state with fresh data
          setIsLoading(false) // Set loading to false after fetching
        } else {
          console.error('Season details fetch failed:', result.data.message)
          if (!localData) {
            // Set loading to false only if no local data was used
            setIsLoading(false)
          }
        }
      })
      .catch((error) => {
        console.error('Error calling getSeasonDetails function:', error)
        if (!localData) {
          // Set loading to false only if no local data was used
          setIsLoading(false)
        }
      })
  }, []) // Empty dependency array means this effect runs once on mount

  const getContent = () => {
    if (!walletAddress) {
      return null
    } else if (walletAddress) {
      return <Quest walletAddress={walletAddress} onSubmit={() => {}} isMobie={isMobile} />
    }
  }

  const getSubheading = () => {
    if (!walletAddress) {
      return (
        <>
          {isMobile && (
            <>
              <VeryHighlightedText>We recommend using Truly points on desktop.</VeryHighlightedText>
              <br />
              <br />
            </>
          )}
          Truly is a real estate investment protocol. We put U.S. real estate on a permissionless token to make it easy
          for global investors to invest in American properties.
        </>
      )
    } else {
      return (
        <>
          {isMobile && (
            <>
              <VeryHighlightedText>We recommend using Truly points on desktop.</VeryHighlightedText>
              <br />
              <br />
            </>
          )}
          Complete the five steps below to join the points program. Wallets whitelisted in Epoch #1 are eligible for{' '}
          <VeryHighlightedText>future special opportunities.</VeryHighlightedText>
        </>
      )
    }
  }

  const getBonusContent = () => {
    if (!walletAddress) {
      return null
    } else if (seasonDetails) {
      return (
        <BonusContentContainer>
          <PointsCampaign
            epoch={1} // Hardcoded for now
            endDate={seasonDetails.endDate}
            name={seasonDetails.name}
            totalSeasonPoints={seasonDetails.totalPoints}
            isLoading={isLoading}
          />
        </BonusContentContainer>
      )
    } else {
      return null
    }
  }

  return (
    <Container>
      {/* Adjust according to whether the wallet is connected and whether the user has joined the program */}
      {!walletAddress || !userProgress || !userProgress.joinedPointsProgram ? (
        <JustText
          headerMode={'dark'}
          hideHeaderBorder={false}
          mode={'dark'}
          roundedHeaderButton={true}
          title={'Join The Points Program'}
          heading={
            <>
              Permissionless U.S. real estate. <br /> Be early. <HighlightedText>Get Rewarded.</HighlightedText>
            </>
          }
          subheading={getSubheading()}
          bonusContent={getBonusContent()}
          showInvestors={true}
          showButton={!walletAddress}
          buttonOnClick={() => {
            connectWallet()
            Amplitude.getInstance().logEvent('button clicked', {
              buttonName: 'connect wallet',
              pageName: 'points',
              url: window.location.href,
            })
          }}
          buttonText={'Connect Wallet ➤'}
          showSplash={false}
          showGhostLogo={true}
          isAtBottomStyles={tw`absolute mr-[-15rem] md:mr-[-16rem]`}
          notAtBottomStyles={tw`fixed mr-[-15rem] right-[-2rem]`}
          content={getContent()}
          containerStyles={walletAddress ? tw`pt-16 md:pt-32` : null}
        />
      ) : (
        <PointsHome />
      )}
      <SimpleFiveColumn />
    </Container>
  )
}

export default Points
