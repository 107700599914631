import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js'
import { PrimaryButton as PrimaryButtonBase, SecondaryButton as SecondaryButtonBase } from 'components/misc/Buttons.js'
import Chart from 'components/misc/Chart.js'
import TeamIllustrationSrc from 'assets/team-illustration-2.svg'
import { ReactComponent as SvgDotPattern } from 'assets/dot-pattern.svg'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 sm:pt-8 sm:pb-20 md:py-16 items-center`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative sm:hidden`

const TextColumn = styled(Column)(({ textOnLeft }) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const Description = tw.p`font-light font-sans mt-4 text-left text-xl leading-relaxed text-blue-500`

const Image = styled.img(({ imageRounded, imageBorder, imageShadow }) => [
  tw`m-auto max-h-[40rem]`,
  imageRounded && tw`rounded-lg`,
  imageBorder && tw`border`,
  imageShadow && tw`shadow`,
])

const MobileImageContainer = tw.div`pt-10 pb-2 2xl:hidden xl:hidden lg:hidden md:hidden`

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-aqua-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(
  SubheadingBase
)`text-center md:text-left font-bold font-sans tracking-widest word-spacing-wide uppercase text-turquoise text-sm md:text-base mb-6`
const Heading = tw(
  SectionHeading
)`mt-4 font-light font-serif text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const SecondaryButtonStyled = tw(SecondaryButtonBase)`mt-12`

const Steps = tw.ul`mt-12`
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`
const StepNumber = tw.div`font-semibold font-serif text-4xl leading-none text-gray-500 w-12`
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`
const StepHeading = tw.h6`leading-none text-2xl font-medium font-sans text-blue-500`
const StepDescription = tw.p`mt-3 max-w-xs text-lg text-blue-500 font-light font-sans`

function TextColumnContent({ mode, subheading, heading, description, steps, showButton, buttonText, buttonOnclick }) {
  return (
    <TextContent>
      <Subheading>{subheading}</Subheading>
      <Heading css={[mode == 'dark' && tw`text-gray-100`]}>{heading}</Heading>
      <Description css={[mode == 'dark' && tw`text-gray-200`]}>{description}</Description>
      {steps && (
        <Steps>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
              <StepText>
                <StepHeading css={[mode == 'dark' && tw`text-gray-100`]}>{step.heading}</StepHeading>
                <StepDescription css={[mode == 'dark' && tw`text-gray-200`]}>{step.description}</StepDescription>
              </StepText>
            </Step>
          ))}
        </Steps>
      )}
      {showButton && <SecondaryButtonStyled onClick={buttonOnclick}>{buttonText}</SecondaryButtonStyled>}
    </TextContent>
  )
}

const MemoizedTextColumnContent = React.memo(TextColumnContent)

export default function Component(props) {
  const {
    imageSrc,
    imageRounded,
    imageBorder,
    imageShadow,
    imageTitle,
    lottie,
    blockImage,
    graphMode,
    mode,
    textOnLeft,
  } = props

  return (
    <Container css={[mode == 'dark' && tw`bg-gray-900 -mx-12`]}>
      <TwoColumn>
        <ImageColumn>
          {graphMode ? (
            <Chart
              title={imageTitle}
              lottie={lottie}
              blockImage={blockImage}
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          ) : (
            <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          )}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <MemoizedTextColumnContent {...props} />
          <MobileImageContainer>
            {graphMode ? (
              <Chart
                title={imageTitle}
                lottie={lottie}
                blockImage={blockImage}
                src={imageSrc}
                imageBorder={imageBorder}
                imageShadow={imageShadow}
                imageRounded={imageRounded}
              />
            ) : (
              <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
            )}
          </MobileImageContainer>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
