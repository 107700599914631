import React, { useState, useEffect, createContext } from 'react'
import useGeoLocation from 'react-ipgeolocation'

export const LocationContext = createContext()

export const LocationProvider = ({ children }) => {
  const [isInUS, setIsInUS] = useState(() => {
    // Attempt to read the user's location from local storage on initial load
    const storedIsInUS = localStorage.getItem('isInUS')
    return storedIsInUS !== null ? JSON.parse(storedIsInUS) : null
  })

  const location = useGeoLocation()

  useEffect(() => {
    // Function to fetch server-side location if client-side fails
    const fetchServerSideLocation = async () => {
      const functionUrl =
        !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
          ? 'https://us-central1-villcaso-dev.cloudfunctions.net/getUserCountry'
          : 'https://us-central1-villcaso-prod.cloudfunctions.net/getUserCountry'
      try {
        const response = await fetch(functionUrl)
        if (!response.ok) throw new Error('Server-side location fetch failed')
        const data = await response.json()
        if (data && data.countryCode) {
          const newIsInUS = data.countryCode === 'US'
          setIsInUS(newIsInUS)
          localStorage.setItem('isInUS', JSON.stringify(newIsInUS))
        } else {
          console.error('Server-side geolocation failed: Response is missing data field.', data)
          setIsInUS(null)
        }
      } catch (error) {
        console.error('Server-side geolocation failed:', error)
        setIsInUS(null)
      }
    }

    if (location.error) {
      // If there's an error from useGeoLocation (e.g., due to ad blocker), try server-side fetch
      fetchServerSideLocation()
    } else if (location && location.country) {
      const newIsInUS = location.country === 'US'
      if (newIsInUS !== isInUS) {
        setIsInUS(newIsInUS)
        localStorage.setItem('isInUS', JSON.stringify(newIsInUS))
      }
    } else {
      // No location data and no error - location might still be loading, or we have not tried fetching it yet
      // You can decide to call fetchServerSideLocation here if you don't expect to use react-ipgeolocation
      // Or if you know that client-side fetching will likely fail in most cases
    }
  }, [location, isInUS])

  return <LocationContext.Provider value={{ isInUS }}>{children}</LocationContext.Provider>
}
