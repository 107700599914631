import React, { useEffect } from 'react'
import tw from 'twin.macro'
import Lottie from 'lottie-react'
import Turnstile from 'react-turnstile'

import loadingAnimation from 'assets/lottie/siriLoading.json'

const ModalBackdrop = tw.div`fixed inset-0 bg-black opacity-[0.8] z-[100]`
const ModalContainer = tw.div`fixed inset-0 flex flex-col items-center justify-center text-center z-[100]`
const ModalBody = tw.p`text-2xl font-sans font-light text-white my-6 tracking-widest uppercase`

const ProcessingModal = ({ isOpen, onClose, text, onVerify, renderTurnstile }) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = isOpen ? 'hidden' : originalStyle
    return () => {
      document.body.style.overflow = originalStyle // Revert on cleanup
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <>
      <ModalBackdrop onClick={onClose} />
      <ModalContainer>
        <Lottie animationData={loadingAnimation} style={{ width: 160, height: 160 }} loop autoplay />
        <ModalBody>{text}</ModalBody>
        {renderTurnstile && (
          <Turnstile
            sitekey="0x4AAAAAAAdp3FUR1t7UD6kP"
            onVerify={(token) => {
              console.log('Turnstile token received:', token)
              onVerify(token)
            }}
          />
        )}
      </ModalContainer>
    </>
  )
}

export default ProcessingModal
