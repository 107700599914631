// ProcessingModal.js
import React from 'react'
import tw from 'twin.macro'
import Lottie from 'lottie-react'

import Fire from '../../assets/lottie/fire.json'

const Container = tw.div`max-w-screen-xl mx-auto mt-16 flex flex-row items-center justify-center shadow-crypto-md border-turquoise border-[3px] rounded-lg p-4 bg-gray-900 z-10 relative`
const ImageContainer = tw.div`mr-4`
const Text = tw.p`text-sm md:text-lg font-sans font-light text-white`

const Billboard = ({ text }) => {
  return (
    <>
      <Container>
        <ImageContainer>
          <Lottie animationData={Fire} style={{ width: 50, height: 50 }} loop autoplay />
        </ImageContainer>

        <Text>{text}</Text>
      </Container>
    </>
  )
}

export default Billboard
