import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Spinner from '../misc/Spinner'

const StandingsContainer = tw.div`flex flex-row gap-4 justify-between items-start mt-6 md:mt-8`
const StandingsContentItem = styled.div`
  ${tw`flex flex-col justify-between justify-items-start`}
`
const SpinnerContainer = tw.div``
const TableValue = tw.p`text-white font-sans text-sm md:text-lg font-medium h-6 md:h-10 flex items-center justify-start`

const TableValueContainer = tw.div`text-white font-sans text-lg font-medium h-6 md:h-10 flex items-center justify-start`
const Label = tw.p`text-2xs md:text-xs text-gray-500 font-sans uppercase font-bold tracking-widest`

const Standings = ({ leaderboardData, isLoading }) => {
  const miniData =
    leaderboardData.length > 10
      ? [
          leaderboardData[0],
          leaderboardData[1],
          leaderboardData[2],
          leaderboardData[3],
          leaderboardData[4],
          leaderboardData[5],
          leaderboardData[6],
          leaderboardData[7],
          leaderboardData[8],
          leaderboardData[9],
        ]
      : leaderboardData
  return (
    <StandingsContainer>
      <StandingsContentItem style={{ position: 'absolute' }}>
        <Label style={{ opacity: 0, marginBottom: '0.5rem' }}>Rank</Label> {/* Placeholder for rank column */}
        {miniData.map((user, index) => (
          <TableValueContainer key={`rank-${index}`}>
            <Label>{index + 1}</Label>
          </TableValueContainer>
        ))}
      </StandingsContentItem>

      <StandingsContentItem style={{ marginLeft: '3rem' }}>
        <Label style={{ marginBottom: '0.5rem' }}>Wallet</Label>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          miniData.map((user, index) => (
            <TableValue key={`wallet-${index}`}>
              {user.walletAddress.slice(0, 6)}...{user.walletAddress.slice(-4)}
            </TableValue>
          ))
        )}
      </StandingsContentItem>

      <StandingsContentItem>
        <Label style={{ marginBottom: '0.5rem' }}>Points</Label>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          miniData.map((user, index) => (
            <TableValue key={`points-${index}`}>
              {user.totalPoints ? Math.round(user.totalPoints).toLocaleString() : '0'}
            </TableValue>
          ))
        )}
      </StandingsContentItem>

      <StandingsContentItem>
        <Label style={{ marginBottom: '0.5rem' }}>Referrals</Label>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          miniData.map((user, index) => (
            <TableValue key={`referrals-${index}`}>
              {user.referralsCount ? user.referralsCount.toLocaleString() : '0'}
            </TableValue>
          ))
        )}
      </StandingsContentItem>
    </StandingsContainer>
  )
}

export default Standings
