import React, { useState, useContext, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import Amplitude from 'amplitude-js'
import { FaChevronDown } from 'react-icons/fa' // For the dropdown arrow icon
import { useLocation, useNavigate } from 'react-router-dom'

import { useModal } from '../../contexts/ModalContext'
import { LocationContext } from '../../contexts/LocationContext'
import { useWallet } from '../../contexts/WalletContext.js'

import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler.js'
import ReactModalAdapter from '../../helpers/ReactModalAdapter.js'
import getDocsendLink from 'config/docsend'

import SocialLinks from '../misc/SocialLinks.js'
import logoLight from '../../assets/logos/logoLight.svg'
import logoDark from '../../assets/logos/logoDark.svg'
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg'
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg'

import { SiMedium } from 'react-icons/si'
import ProcessingModal from 'components/points/ProcessingModal'
import PointsWidget from './PointsWidget'
import { Divider } from 'components/misc/Divider'

const Header = styled.header(({ hideBorder }) => [
  tw`flex justify-between items-center max-w-screen-xl mx-auto pb-6 border-b border-blue-300 pt-10`,
  hideBorder && tw`border-none`,
])

export const NavLinks = tw.div`inline-flex items-center`

/* hocus: stands for "on hover or focus"
 * hocus:bg-turquoise will apply the bg-turquoise class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-base lg:mx-6 lg:my-0
  font-bold font-sans tracking-wide transition duration-300 text-gray-900
  border-b-2 border-transparent hover:border-aqua-500 hocus:text-aqua-500
`

export const PageSwitchLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-aqua-500 text-black font-sans text-base
  bg-gradient-to-r from-[#34EEC9] via-[#65CEF1] to-[#838EE2]
  hocus:bg-turquoise hocus:text-white focus:shadow-outline
  border-b-0 cursor-pointer
  rounded-full
  flex flex-row items-center
`

export const PrimaryLink = tw(NavLink)`
  lg:mx-0 lg:ml-5
  px-8 py-3 rounded bg-aqua-500 text-gray-900
  bg-gradient-to-r from-aqua-500 to-green-500
  hocus:bg-turquoise hocus:text-white focus:shadow-outline
  border-b-0 cursor-pointer
`

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-display font-black leading-tight border-b-0 text-3xl! ml-0!`};

  img {
    ${tw`w-40 mr-3`}
  }
`

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-aqua-500 transition duration-300
`
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-50 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-tertiary-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`)

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center relative z-10
`

export const WalletDropdownMenu = styled.div`
  ${tw`absolute right-[12px] top-[55px] py-2 bg-white rounded-lg shadow-xl z-50 text-tertiary-900 border-gray-900 border-solid border`}
`

export const WalletDropdownItem = styled.button`
  ${tw`transition-all duration-300 block px-4 py-2 text-sm text-gray-700 font-sans font-semibold cursor-pointer hover:text-turquoise`}
`

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  mobileLinks,
  className,
  collapseBreakpointClass = 'lg',
  mode,
  hideBorder = false,
}) => {
  const { openModal } = useModal()
  const { isInUS } = useContext(LocationContext)
  const { walletAddress, userProgress, connectWallet, disconnectWallet, connectionStatus } = useWallet()
  const userType = isInUS ? 'us-retail' : 'global-retail' // anytime someone accesses the form from the navbar, they get the retail experience

  const [isWalletDropdownOpen, setIsWalletDropdownOpen] = useState(false)

  const location = useLocation()
  const navigate = useNavigate() // If you choose to navigate programmatically

  const isOnPointsPage = location.pathname === '/points'

  // Ref for MobileNavLinks
  const mobileNavLinksRef = useRef(null)

  // Use the hook to close MobileNavLinks when clicked outside
  useOutsideClick(mobileNavLinksRef, () => {
    if (showNavLinks) {
      toggleNavbar()
    }
  })

  // Ref for WalletDropdown
  const walletDropdownRef = useRef(null)

  useOutsideClick(walletDropdownRef, () => {
    if (isWalletDropdownOpen) setIsWalletDropdownOpen(false)
  })

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler()
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass]

  const ushTokenLink = [
    <NavLink
      href="/ush"
      css={[mode == 'dark' && !showNavLinks && tw`text-white`]}
      onClick={(e) => {
        Amplitude.getInstance().logEvent('ush fund link clicked')
      }}
      key={6}
    >
      USH Token
    </NavLink>,
  ]

  const blogLink = [
    <NavLink
      href={'https://blog.trulybased.com'}
      target="_blank"
      css={[mode == 'dark' && !showNavLinks && tw`text-white`]}
      onClick={(e) => {
        Amplitude.getInstance().logEvent('page viewed', { page: 'blog' })
      }}
      key={7}
    >
      Blog
    </NavLink>,
  ]

  const docsLink = [
    <NavLink
      href={'https://docs.trulybased.com'}
      target="_blank"
      css={[mode == 'dark' && !showNavLinks && tw`text-white`]}
      onClick={(e) => {
        Amplitude.getInstance().logEvent('page viewed', { page: 'docs' })
      }}
      key={8}
    >
      Docs
    </NavLink>,
  ]

  const contactLink = [
    <NavLink
      href={'/contact'}
      css={[mode == 'dark' && !showNavLinks && tw`text-white`]}
      onClick={(e) => {
        Amplitude.getInstance().logEvent('contact link clicked')
      }}
      key={9}
    >
      Contact
    </NavLink>,
  ]

  const mobileDisconnectLink = [
    <NavLink
      css={[mode == 'dark' && !showNavLinks && tw`text-white`]}
      onClick={(e) => {
        disconnectAndCloseDropdown()
      }}
      key={10}
    >
      Disconnect Wallet
    </NavLink>,
  ]

  const mobileConnectLink = [
    <NavLink
      css={[mode == 'dark' && !showNavLinks && tw`text-white`]}
      onClick={(e) => {
        toggleWalletDropdown()
        connectWallet()
        Amplitude.getInstance().logEvent('button clicked', {
          buttonName: 'mobile connect wallet',
          pageName: 'nav bar',
          url: window.location.href,
        })
      }}
      key={11}
    >
      Connect Wallet
    </NavLink>,
  ]

  const mobilePointsLink = [
    <NavLink
      href="/points"
      css={[mode == 'dark' && !showNavLinks && tw`text-white`]}
      onClick={(e) => {
        Amplitude.getInstance().logEvent('mobile points link clicked')
      }}
      key={12}
    >
      Points
    </NavLink>,
  ]

  const toggleWalletDropdown = () => setIsWalletDropdownOpen(!isWalletDropdownOpen)

  const cta = walletAddress ? (
    <PointsWidget
      onClick={() => {
        toggleWalletDropdown()
        Amplitude.getInstance().logEvent('button clicked', {
          buttonName: 'toggle wallet dropdown',
          pageName: 'nav bar',
          url: window.location.href,
        })
      }}
      mode={mode}
    />
  ) : (
    <PageSwitchLink
      key={11}
      css={roundedHeaderButton && tw`rounded-full`}
      onClick={() => {
        connectWallet()
        Amplitude.getInstance().logEvent('button clicked', {
          buttonName: 'connect wallet',
          pageName: 'nav bar',
          url: window.location.href,
        })
      }}
    >
      Connect Wallet
    </PageSwitchLink>
  )

  const disconnectAndCloseDropdown = () => {
    disconnectWallet()
    Amplitude.getInstance().logEvent('button clicked', {
      buttonName: 'disconnect wallet',
      pageName: 'nav bar',
      url: window.location.href,
    })
    setIsWalletDropdownOpen(false) // Close the dropdown
  }

  const globalLinkSet = [
    <NavLinks key={9}>
      {ushTokenLink}
      {docsLink}
      {blogLink}
      {contactLink}
    </NavLinks>,
  ]

  const defaultLogoLink = (
    <LogoLink href="/" key={10}>
      <img src={mode == 'dark' ? logoLight : logoDark} alt="logo" />
      <span css={[mode == 'dark' && tw`text-white`]}></span>
    </LogoLink>
  )

  logoLink = logoLink || defaultLogoLink

  // Use the same linkset for everyone (access is restricted when the user clicks into the page)
  if (isInUS) {
    links = globalLinkSet
    mobileLinks = globalLinkSet
  } else if (!isInUS) {
    links = globalLinkSet
    mobileLinks = globalLinkSet
  }

  return (
    <Header
      className={className || 'header-light'}
      css={[mode == 'dark' && tw`border-gray-500`]}
      hideBorder={hideBorder}
    >
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        <div css={tw`hidden lg:flex flex-1 justify-between items-center pr-8`}>
          {logoLink}
          {links}
        </div>

        {cta}
        {isWalletDropdownOpen && (
          <WalletDropdownMenu ref={walletDropdownRef}>
            {!isOnPointsPage && (
              <WalletDropdownItem onClick={() => navigate('/points')}>Go to Points</WalletDropdownItem>
            )}
            <WalletDropdownItem onClick={disconnectAndCloseDropdown}>Disconnect Wallet</WalletDropdownItem>
          </WalletDropdownMenu>
        )}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <>
          <MobileNavLinks
            ref={mobileNavLinksRef} // Attach the ref
            initial={{ x: '150%' }} // Starts off the screen
            animate={animation} // Animation control
            css={collapseBreakpointCss.mobileNavLinks}
          >
            {mobileLinks}

            <Divider customStyle={tw`w-full mx-0 my-4 border-gray-500`} />

            {walletAddress ? (
              <div
                style={{
                  display: 'flex', // Sets the flexbox layout
                  flexDirection: 'column',
                  alignItems: 'center', // Aligns items vertically in the center
                  justifyContent: 'space-between', // Spreads the items evenly
                }}
              >
                {mobilePointsLink}
                {mobileDisconnectLink}
              </div>
            ) : (
              <>{mobileConnectLink}</>
            )}
          </MobileNavLinks>

          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? 'open' : 'closed'}
            css={[mode == 'dark' && tw`text-white`]}
          >
            {showNavLinks ? null : <MenuIcon tw="w-6 h-6" />}
          </NavToggle>
        </>
      </MobileNavLinksContainer>

      {connectionStatus && <ProcessingModal isOpen={true} onClose={() => {}} text={connectionStatus} />}
    </Header>
  )
}

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
}
