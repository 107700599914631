import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import { PrimaryButton } from 'components/misc/Buttons.js'
import { LocationContext } from '../../contexts/LocationContext' // Adjust the path as necessary
import mailchimpConfig from '../../config/mailchimp'

const Form = tw.form`space-y-4`
const Input = tw.input`font-sans font-normal pl-8 py-4 rounded border-2 w-full focus:outline-none transition duration-300 border-gray-500 focus:border-aqua-500 hover:border-gray-500 mb-4`
const CheckboxGroup = tw.div`flex items-center space-x-2 mb-4`
const Checkbox = tw.input`rounded text-indigo-600 focus:ring-indigo-500 border-gray-300`
const CheckboxLabel = tw.label`font-sans block text-base font-light text-blue-500`
const ModalLabel = tw.label`font-sans block text-base font-light text-blue-500 mb-2`
const SuccessMessageContainer = tw.div`text-center p-10`
const SuccessTitle = tw.h2`text-2xl font-bold font-sans text-aqua-500 mb-4`
const SuccessDescription = tw.p`text-base font-light font-sans text-blue-500`
const PrimaryButtonError = styled(PrimaryButton)`
  ${tw`bg-red-700 hover:bg-red-900 font-semibold text-sm`}
`

const CheckboxContainer = tw.div`pt-2`

const { postUrl } = mailchimpConfig

const InstitutionalLeadCapture = ({ userEmail, userType }) => {
  const { isInUS } = useContext(LocationContext)
  const [fullName, setFullName] = useState('')
  const [institutionName, setInstitutionName] = useState('')
  const [email, setEmail] = useState(userEmail)
  const [hasHEIExposure, setHasHEIExposure] = useState(false)
  const [isQualifiedBuyer, setIsQualifiedBuyer] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [buttonText, setButtonText] = useState('Next')
  const [buttonErrorState, setButtonErrorState] = useState(false)

  const handleInputChange = (event) => {
    const { id, value } = event.target
    if (id === 'full-name') {
      setFullName(value)
    } else if (id === 'institution-name') {
      setInstitutionName(value)
    } else if (id === 'email') {
      setEmail(value)
    }
  }

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target
    if (id === 'hei-exposure') {
      setHasHEIExposure(checked)
    } else if (id === 'qualified-buyer') {
      setIsQualifiedBuyer(checked)
    }
  }

  const prepareFormData = () => ({
    MERGE0: email,
    MERGE2: userType,
    MERGE14: fullName,
    MERGE15: institutionName,
    MERGE16: hasHEIExposure ? 'Yes' : 'No',
    MERGE17: isQualifiedBuyer ? 'Yes' : 'No',
  })

  const handleSubmit = (e, subscribe) => {
    e.preventDefault()
    const formData = prepareFormData()
    subscribe(formData)
  }

  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => {
        // Error handling
        if (status === 'error' && !formSubmitted) {
          setButtonErrorState(true)
          // Clean up the error message from Mailchimp if needed
          setButtonText(message.includes(' - ') ? message.split(' - ')[1] : message)
        } else if (status === 'success') {
          setFormSubmitted(true)
          setButtonErrorState(false)
        }

        if (status === 'success' && !formSubmitted) {
          setFormSubmitted(true)
        }

        // If the form was submitted successfully, render the success message
        if (formSubmitted) {
          return (
            <SuccessMessageContainer>
              <SuccessTitle>Thank You!</SuccessTitle>
              <SuccessDescription>
                Your information was successfully submitted. You've been added to the waitlist.
              </SuccessDescription>
            </SuccessMessageContainer>
          )
        }
        return (
          <Form onSubmit={(e) => handleSubmit(e, subscribe)}>
            {/* Full Name Input */}
            <div>
              <ModalLabel htmlFor="full-name">Full Name</ModalLabel>
              <Input
                id="full-name"
                type="text"
                placeholder="First Last"
                value={fullName}
                onChange={handleInputChange}
              />
            </div>

            {/* Institution Name Input */}
            <div>
              <ModalLabel htmlFor="institution-name">Institution Name</ModalLabel>
              <Input
                id="institution-name"
                type="text"
                placeholder="Institution Name"
                value={institutionName}
                onChange={handleInputChange}
              />
            </div>

            {/* Email Input */}
            <div>
              <ModalLabel htmlFor="email">Email Address</ModalLabel>
              <Input id="email" type="email" placeholder="Email Address" value={email} onChange={handleInputChange} />
            </div>

            <hr />

            {/* Checkboxes */}
            <CheckboxContainer>
              <CheckboxGroup>
                <Checkbox id="hei-exposure" type="checkbox" checked={hasHEIExposure} onChange={handleCheckboxChange} />
                <CheckboxLabel htmlFor="hei-exposure">My institution currently has HEI exposure.</CheckboxLabel>
              </CheckboxGroup>

              <CheckboxGroup>
                <Checkbox
                  id="qualified-buyer"
                  type="checkbox"
                  checked={isQualifiedBuyer}
                  onChange={handleCheckboxChange}
                />
                <CheckboxLabel htmlFor="qualified-buyer">I am a qualified institutional buyer.</CheckboxLabel>
              </CheckboxGroup>
            </CheckboxContainer>

            {/* Submit Button */}
            {buttonErrorState ? (
              <PrimaryButtonError type="submit">{buttonText}</PrimaryButtonError>
            ) : (
              <PrimaryButton type="submit">{buttonText}</PrimaryButton>
            )}
          </Form>
        )
      }}
    />
  )
}

export default InstitutionalLeadCapture
