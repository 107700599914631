import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Spinner from '../misc/Spinner'

const PointContent = tw.div`flex flex-row gap-4 justify-between items-start mt-4`
const PointContentItem = tw.div`flex flex-col justify-between justify-items-start`
const Label = tw.p`text-2xs md:text-xs text-gray-500 font-sans uppercase font-bold tracking-widest`
const Value = tw.div`text-white font-sans text-lg md:text-2xl font-light mt-1 md:mt-2`

const YourPoints = ({ userProgress, rank }) => {
  const getOrdinalNumber = (n) => {
    const s = ['th', 'st', 'nd', 'rd'],
      v = n % 100
    return s[(v - 20) % 10] || s[v] || s[0]
  }

  return (
    <PointContent>
      <PointContentItem>
        <Label>Total</Label>
        <Value>{userProgress ? Math.round(userProgress.totalPoints).toLocaleString() : <Spinner />}</Value>
      </PointContentItem>
      <PointContentItem>
        <Label>Rank</Label>
        <Value>{rank === null ? <Spinner /> : `${rank.toLocaleString()}${getOrdinalNumber(rank)}`}</Value>
      </PointContentItem>
      <PointContentItem>
        <Label>Referrals</Label>
        <Value>
          {userProgress?.referralsCount == null ? <Spinner /> : userProgress.referralsCount.toLocaleString()}
        </Value>
      </PointContentItem>
    </PointContent>
  )
}

export default YourPoints
