import React, { useEffect, useState } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const FingerprintContext = React.createContext(null)

export const FingerprintProvider = ({ children }) => {
  const [fingerprint, setFingerprint] = useState(null)

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      setFingerprint(result.visitorId)
    }

    loadFingerprint()
  }, [])

  return <FingerprintContext.Provider value={fingerprint}>{children}</FingerprintContext.Provider>
}
