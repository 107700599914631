// src/context/ModalContext.js
import React, { createContext, useContext, useState } from 'react'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({}) // Initialize with an empty object

  const openModal = (data) => {
    setModalData(data) // data contains title, subtitle, userType
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalData({})
    setModalOpen(false)
  }

  const contextValue = {
    isModalOpen,
    openModal,
    closeModal,
    modalData, // Pass modal data to consumers
  }

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
