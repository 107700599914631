import React from 'react'
import tw, { styled } from 'twin.macro'

// Styled components using twin.macro
const Container = tw.section`flex flex-row items-center justify-center bg-gray-900 -mx-12 py-4`
const TitleContainer = tw.div`flex items-center justify-center mr-12`
const CarouselTitle = tw.h2`text-center font-bold font-sans uppercase text-gray-500 text-sm md:text-sm tracking-widest word-spacing-wide`

const Logo = styled.img`
  ${tw`mx-2`}; // mx-2 for horizontal margins
  height: var(--logo-height, 1.5rem);
  width: auto;
`

// Updated Logos component
const Logos = ({ logos }) => {
  // Handler for logo click
  const handleLogoClick = (url) => {
    url && window.open(url, '_blank', 'noopener,noreferrer')
  }

  return logos.map((logo, index) => (
    <Logo
      key={index}
      src={logo.src}
      alt={logo.alt}
      onClick={() => handleLogoClick(logo.link)}
      link={logo.link} // Pass the link as a prop
    />
  ))
}

// StaticBanner component
const StaticBanner = ({ logos, title }) => {
  return (
    <Container>
      <TitleContainer>
        <CarouselTitle>{title}</CarouselTitle>
      </TitleContainer>
      <Logos logos={logos} />
    </Container>
  )
}

export default StaticBanner
