// src/pages/LoadingPage.js

import React from 'react'
import tw from 'twin.macro'

const LoadingContainer = tw.div`flex justify-center items-center h-screen`
const LoadingText = tw.p`text-lg font-semibold font-sans text-center p-4`

const LoadingPage = () => {
  return (
    <LoadingContainer>
      <LoadingText>Determining your location...</LoadingText>
    </LoadingContainer>
  )
}

export default LoadingPage
