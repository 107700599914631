import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { useWallet } from '../../contexts/WalletContext.js'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

// Styled Components
const Container = styled.a`
  ${tw`flex items-center cursor-pointer`}
`
const Avatar = styled.div`
  ${tw`w-10 h-10 rounded-full mr-4 relative bg-gradient-to-r from-[#34EEC9] via-[#65CEF1] to-[#838EE2]`}
`

const Badge = styled.div`
  ${tw`border-2 border-white absolute top-[-5px] right-[-5px] bg-blue-500 text-white text-xs font-serif font-black rounded-full w-6 h-6 flex items-center justify-center`}
`

const Info = styled.div`
  ${tw`flex flex-col justify-between items-end`}
`

const Points = styled.div`
  ${tw`font-bold text-sm font-sans uppercase tracking-widest`}
  ${({ mode }) => (mode === 'light' ? tw`text-turquoise` : tw`text-turquoise-light`)}
  text-shadow: ${({ mode }) =>
    mode === 'light'
      ? 'none'
      : '0 0 10px rgba(52, 238, 201, 0.75), 0 0 20px rgba(101, 206, 241, 0.75), 0 0 30px rgba(131, 142, 226, 0.75)'};
`
const Wallet = styled.div`
  ${tw`text-sm font-sans tracking-wide`}
  ${({ mode }) => (mode === 'light' ? tw`text-gray-800 font-medium` : tw`text-gray-500`)}
`

const PointsTransition = styled.div`
  transition: opacity 0.4s ease-in-out;
  &.points-exit-active {
    opacity: 0;
    display: none;
  }
  &.points-enter-active {
    opacity: 1;
  }
  &.points-enter {
    opacity: 0;
  }
  &.points-enter-done {
    opacity: 1;
  }
`

const PointsWidget = ({ onClick, mode }) => {
  const { walletAddress, userProgress } = useWallet()
  const [points, setPoints] = useState(userProgress?.totalPoints || 0)
  const [key, setKey] = useState(0) // key to force re-rendering

  useEffect(() => {
    if (userProgress?.totalPoints !== points) {
      setKey((prevKey) => prevKey + 1) // Increment key to trigger exit
      setPoints(userProgress?.totalPoints || 0)
    }
  }, [userProgress, points])

  // Checks to see if the user joined in the first season (Before June 1, 2024)
  const hasBadge =
    userProgress?.joinedPointsProgram?._seconds !== undefined &&
    new Date(userProgress.joinedPointsProgram._seconds * 1000) < new Date('2024-06-01')

  return (
    <Container onClick={onClick}>
      <Avatar>{hasBadge && <Badge>1st</Badge>}</Avatar>
      <Info>
        <TransitionGroup component={null}>
          <CSSTransition
            key={key}
            timeout={500}
            classNames="points"
            onExited={() => setPoints(userProgress?.totalPoints || 0)}
          >
            <PointsTransition>
              <Points mode={mode}>{Math.round(points).toLocaleString()} pts</Points>
            </PointsTransition>
          </CSSTransition>
        </TransitionGroup>
        {walletAddress ? (
          <Wallet mode={mode}>{`${walletAddress.substring(0, 6)}...${walletAddress.substring(
            walletAddress.length - 4
          )}`}</Wallet>
        ) : (
          <Wallet mode={mode}>Loading...</Wallet>
        )}
      </Info>
    </Container>
  )
}

export default PointsWidget
