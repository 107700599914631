import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'

// Disable console logs in production
if (process.env.NODE_ENV === 'production') {
  console.log = console.error = console.warn = console.info = () => {}
}

const container = document.getElementById('root')
const root = createRoot(container) // creates a root
root.render(<App />)
