import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import styled from 'styled-components'
import { useModal } from '../contexts/ModalContext'
import { LocationContext } from '../contexts/LocationContext'
import { useWallet } from '../contexts/WalletContext'

import SimpleFiveColumn from 'components/footers/SimpleFiveColumn.js'
import TwoCol from 'components/features/TwoCol'

import Amplitude from 'amplitude-js'

// Images
import BackedByHeis from '../assets/featureImages/BackedByHeis.svg'
import BankruptcyRemote from '../assets/featureImages/BankruptcyRemote.svg'
import Composable from '../assets/featureImages/Composable.svg'
import EarnRewards from '../assets/featureImages/EarnRewards.svg'
import IndependentOversight from '../assets/featureImages/IndependentOversight.svg'
import InstitutionalStructuring from '../assets/featureImages/InstitutionalStructuring.svg'
import InstantLiquidity from '../assets/featureImages/InstantLiquidity.svg'

// Icons
import arrows from '../assets/icons/arrows.svg'
import composable from '../assets/icons/composable.svg'
import eye from '../assets/icons/eye.svg'
import institutional from '../assets/icons/institutional.svg'
import rewards from '../assets/icons/rewards.svg'
import anchor from '../assets/icons/anchor.svg'
import palm from '../assets/icons/palm.svg'

import JustText from 'components/hero/JustText'

export default () => {
  const { openModal } = useModal()
  const { isInUS } = useContext(LocationContext)
  const navigate = useNavigate()
  const { connectWallet } = useWallet()

  const HighlightedText = styled.span`
    ${tw`font-medium`}
  `
  const VeryHighlightedText = styled.span`
    ${tw`font-semibold`}
  `
  const Container = tw.div`pt-0 pb-8 px-4 md:px-8 bg-bone min-h-screen overflow-hidden font-display`
  Amplitude.getInstance().logEvent('page viewed', {
    pageName: 'ush',
    url: window.location.href,
    referrerUrl: document.referrer,
  })

  const userType = isInUS ? 'us-retail' : 'global-retail'
  const modalType = 'retail'

  // if (isInUS) {
  //   // Redirect to restricted page
  //   window.location.href = '/restricted'
  // }

  return (
    <Container>
      <JustText
        mode={'dark'}
        roundedHeaderButton={true}
        title={'Permissionless U.S. real estate'}
        heading={
          <>
            USH is the <HighlightedText>permissionless token</HighlightedText> backed by U.S. real estate.
          </>
        }
        subheading={
          <>
            USH is an institutional-grade token, backed by U.S. residential real estate assets called home equity
            investments (HEIs).
          </>
        }
        showButton={true}
        buttonText={'Join Points Program ➤'}
        buttonOnClick={() => {
          navigate('/points')
          Amplitude.getInstance().logEvent('button clicked', {
            buttonName: 'join points program',
            pageName: 'ush',
            url: window.location.href,
          })
        }}
        showSplash={true}
        hideHeaderBorder={true}
      />

      <TwoCol
        tight={true}
        imageSrc={InstantLiquidity}
        textOnLeft={true}
        heading={<>Instant Liquidity.</>}
        showButton={false}
        showIcon={true}
        iconSrc={arrows}
        description={
          <>
            USH is fully permissionless and free of lockups. You may exchange USH for other assets quickly and
            seamlessly on compatible decentralized exchanges or liquidity protocols.
          </>
        }
      />

      <TwoCol
        tight={true}
        imageSrc={Composable}
        textOnLeft={true}
        heading={<>Composable with DeFi.</>}
        showButton={false}
        showIcon={true}
        iconSrc={composable}
        description={
          <>
            USH is designed to be compatible with other ERC-20 tokens and DeFi products. It can seamlessly interact with
            decentralized applications, smart contracts, and protocols within the Ethereum ecosystem. Leverage the
            token’s functionalities across different DeFi platforms and unlock a range of opportunities for financial
            interactions and services.
          </>
        }
      />

      <TwoCol
        tight={true}
        imageSrc={BackedByHeis}
        textOnLeft={true}
        heading={<>Backed by HEIs.</>}
        showButton={false}
        showIcon={true}
        iconSrc={anchor}
        description={
          <>
            USH is backed by home equity investments (HEIs) — financial instruments secured by lien on owner-occupied
            U.S. residential real estate. HEIs are widely regarded as an exceptional real estate investment, but have
            historically only been available to large institutional investors.
          </>
        }
      />

      <TwoCol
        tight={true}
        imageSrc={IndependentOversight}
        textOnLeft={true}
        heading={<>Independent Oversight.</>}
        showButton={false}
        showIcon={true}
        iconSrc={eye}
        description={
          <>
            The real-world assets of USH are protected by a network of independent third-party professional services
            with commitments to protect the interests and assets of token holders.
          </>
        }
      />

      <TwoCol
        tight={true}
        imageSrc={InstitutionalStructuring}
        textOnLeft={true}
        heading={<>Institutional Structuring.</>}
        showButton={false}
        showIcon={true}
        iconSrc={institutional}
        description={
          <>
            The legal, regulatory, and governance structuring of USH was crafted by Tier-1 U.S., Cayman, and British
            Virgin Island law firms. USH's structure mirrors asset-backed securities issued by the world’s most
            reputable financial institutions.
          </>
        }
      />

      <TwoCol
        tight={true}
        imageSrc={BankruptcyRemote}
        textOnLeft={true}
        heading={<>Bankruptcy Remote.</>}
        showButton={false}
        showIcon={true}
        iconSrc={palm}
        description={
          <>
            The assets backing USH are bankruptcy remote from Truly, and secured by a third party trustee. When you hold
            USH, you have legal claim over the underlying real estate assets.
          </>
        }
      />

      <TwoCol
        tight={true}
        imageSrc={EarnRewards}
        textOnLeft={true}
        heading={<>Earn Rewards.</>}
        showButton={false}
        showIcon={true}
        iconSrc={rewards}
        description={
          <>
            By purchasing and holding USH, you're eligible to earn points that accrue based on the magnitude of your
            investment.
          </>
        }
      />
      <SimpleFiveColumn />
    </Container>
  )
}
