import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Spinner from '../misc/Spinner'
import CountdownTimer from './CountdownTimer'

const Container = styled.div`
  ${tw`flex flex-col`}
`
const Label = tw.p`text-2xs md:text-xs text-gray-500 font-sans uppercase font-bold tracking-widest`
const LabelContainer = tw.div`h-8 md:h-auto`
const SmallValue = tw.p`text-white font-sans text-sm md:text-lg font-medium mt-2`
const SpinnerContainer = tw.div`mt-2`
const PointContentItem = tw.div`flex flex-col justify-between items-start w-full md:w-auto`

const PointContent = tw.div`flex flex-row gap-2 md:gap-4 justify-between items-start mt-4`

const PointCampaignContent = styled(PointContent)`
  ${tw`mt-8`}
`

const PointsCampaign = ({ epoch, endDate, name, totalSeasonPoints, isLoading }) => {
  return (
    <Container>
      <PointCampaignContent>
        <PointContentItem>
          <LabelContainer>
            <Label>Epoch</Label>
          </LabelContainer>

          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <SmallValue>{'#' + epoch}</SmallValue>
          )}
        </PointContentItem>

        <PointContentItem>
          <LabelContainer>
            <Label>Campaign Name</Label>
          </LabelContainer>

          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <SmallValue>{name}</SmallValue>
          )}
        </PointContentItem>

        {/* Hardcode width to prevent jumping from countdown */}
        <PointContentItem style={{ width: '170px' }}>
          <LabelContainer>
            <Label>Countdown</Label>
          </LabelContainer>

          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <CountdownTimer targetDateSeconds={endDate} />
          )}
        </PointContentItem>
        {/* <PointContentItem>
          <Label>Points Issued</Label>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <SmallValue>{totalSeasonPoints.toLocaleString()}</SmallValue>
          )}
        </PointContentItem> */}
      </PointCampaignContent>
    </Container>
  )
}

export default PointsCampaign
