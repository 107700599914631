import { httpsCallable } from 'firebase/functions'

// Function to award points based on referral
export const awardReferralCodeUsedPoints = async (functions, newUserWalletAddress, referralCode) => {
  const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')
  const getReferralCode = httpsCallable(functions, 'getReferralCode')

  try {
    // Retrieve the referral code data
    const referralResponse = await getReferralCode({ referralCode })
    const referralData = referralResponse.data

    if (referralData.type !== 'promotional') {
      const referringUserWalletAddress = referralData.walletAddress
      const referringUserPointsData = {
        walletAddress: referringUserWalletAddress,
        points: 750, // Standard bonus points for a successful referral
        type: 'referral_code_used',
        note: `Referral code used by ${newUserWalletAddress}`,
      }

      // Award points to the referrer
      const result = await createAndUpdatePoints(referringUserPointsData)
      console.log('Referring user points successfully awarded:', result)
      return result
    } else {
      console.log('Promotional code used, no points awarded.')
    }
  } catch (error) {
    console.error('Error awarding referral points:', error)
    throw error // Rethrow to handle in calling function if needed
  }
}
