import React, { useRef, useEffect, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import Lottie from 'lottie-react'

import icon from 'assets/logos/iconDark.svg'

const ImageContent = tw.div`p-8 text-center md:text-left border border-blue-500 rounded border-dashed`
const Redacted = tw.div`bg-gray-300 w-full h-[24rem] font-sans text-center text-gray-500 flex items-center justify-center text-2xl`
const ImageTitle = tw.p`font-bold font-sans text-blue-500 text-lg sm:text-sm ml-2`
const TitleContainer = tw.div`flex flex-row items-center pb-6`
const TitleIcon = styled.img`
  ${tw`w-8 h-8`};
`

const Image = styled.img((props) => [
  props.imageBorder && tw`border-2 border-blue-500`,
  props.imageShadow && tw`shadow-lg`,
])

export default ({ title = 'Lorem Ipsum', src, imageBorder = false, imageShadow = false, lottie, blockImage }) => {
  const [playAnimation, setPlayAnimation] = useState('false')
  const animationRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        if (entry.isIntersecting) {
          console.log('Element is in view')
          setPlayAnimation('true') // deliberately a string
        } else {
          setPlayAnimation('false') // deliberately a string
        }
      },
      {
        threshold: 0.7, // Adjust threshold to ensure the element is significantly in view
      }
    )

    const currentElement = animationRef.current
    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement)
      }
    }
  }, []) // Empty dependencies array ensures this only runs on mount and unmount

  return (
    <ImageContent>
      {blockImage ? (
        <Redacted>Chart redacted in US 🤷</Redacted>
      ) : (
        <>
          <TitleContainer>
            <TitleIcon src={icon} alt="Icon" />
            <ImageTitle>{title}</ImageTitle>
          </TitleContainer>
          {lottie ? (
            <div ref={animationRef} style={{ pointerEvents: 'none' }}>
              <Lottie
                animationData={lottie}
                style={{ height: 'auto', width: 'auto' }}
                play={playAnimation}
                autoplay={true}
                loop={false}
              />
            </div>
          ) : (
            <Image src={src} alt={title} imageBorder={imageBorder} imageShadow={imageShadow} />
          )}
        </>
      )}
    </ImageContent>
  )
}
