import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ReactComponent as ChevronDownIcon } from 'feather-icons/dist/icons/chevron-down.svg'
import Amplitude from 'amplitude-js'

const AccordionsContainer = styled.dl`
  ${tw`max-w-4xl relative`}
  ${({ containerStyle }) => containerStyle && containerStyle}
`

const Accordion = tw.div`cursor-pointer select-none mt-2 md:mt-3 px-4 md:px-5 py-2 md:py-4 rounded-lg text-gray-800 bg-white bg-opacity-10 hover:bg-opacity-15 transition duration-300`
const Header = tw.dt`flex justify-between items-center`
const HeaderText = tw.div`text-xs md:text-base font-normal font-sans text-white flex flex-row justify-between items-center w-full`
const HeaderLeft = tw.p`flex`
const Logo = styled.div`
  ${tw`flex items-center justify-center ml-2`}

  svg {
    ${tw`h-[12px] md:h-[17px] w-auto`}
    width: auto; // Width will adjust to maintain the aspect ratio
  }
`
const HeaderRight = tw.p`flex text-2xs md:text-xs text-white font-sans uppercase font-bold tracking-widest`
const HeaderToggleIcon = motion(styled.span`
  ${tw`ml-1 md:ml-2 transition duration-300 text-white`}
  svg {
    ${tw`w-4 h-4 md:w-6 md:h-6`}
  }
`)
const Body = motion(
  tw.dd`pointer-events-none text-xs md:text-sm leading-relaxed text-left font-sans font-light text-white`
)

export default ({
  accordions = [
    {
      headerLeft: 'Is lunch provided free of cost?',
      headerRight: 'Oink',
      body: 'Yes, it is, if you have a membership with us. Otherwise it is charged as per the menu. Some limits do apply as to how much items can be included in your lunch. This limit is enough for any one person and merely exists to discourage abusal of the system.',
    },
    {
      headerLeft: 'Do you have 2 Bedroom suites?',
      headerRight: 'Oink',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      headerLeft: 'Are Wi-Fi costs included in the price?',
      headerRight: 'Oink',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
  ],
  containerStyle,
}) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null)

  // const toggleAccordion = (accordionIndex) => {
  //   if (activeAccordionIndex === accordionIndex) setActiveAccordionIndex(null)
  //   else setActiveAccordionIndex(accordionIndex)
  // }

  const toggleAccordion = (accordionIndex) => {
    const isOpening = activeAccordionIndex !== accordionIndex
    if (isOpening) {
      // Log the event only when the accordion is being opened
      Amplitude.getInstance().logEvent('accordion opened', {
        pageName: 'points home', // Replace with your actual page name
        accordionTitle: accordions[accordionIndex].headerLeft,
        url: window.location.href,
      })
    }

    // Update state to show or hide the accordion
    setActiveAccordionIndex(isOpening ? accordionIndex : null)
  }

  return (
    <AccordionsContainer containerStyle={containerStyle}>
      {' '}
      {/* Apply containerStyles here */}
      {accordions.map((content, index) => (
        <Accordion
          key={index}
          onClick={() => {
            toggleAccordion(index)
          }}
          className="group"
        >
          <Header>
            <HeaderText>
              <HeaderLeft>
                {content.headerLeft}
                {content.logo && <Logo>{content.logo}</Logo>}
              </HeaderLeft>
              <HeaderRight>{content.headerRight}</HeaderRight>
            </HeaderText>
            <HeaderToggleIcon
              variants={{
                collapsed: { rotate: 0 },
                open: { rotate: -180 },
              }}
              initial="collapsed"
              animate={activeAccordionIndex === index ? 'open' : 'collapsed'}
              transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <ChevronDownIcon />
            </HeaderToggleIcon>
          </Header>
          <Body
            variants={{
              open: { opacity: 1, height: 'auto', marginTop: '16px' },
              collapsed: { opacity: 0, height: 0, marginTop: '0px' },
            }}
            initial="collapsed"
            animate={activeAccordionIndex === index ? 'open' : 'collapsed'}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {content.body}
          </Body>
        </Accordion>
      ))}
    </AccordionsContainer>
  )
}
