import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js'
import { SecondaryButton, PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import Chart from 'components/misc/Chart.js'
import { Container } from 'components/misc/Layouts.js'
import TeamIllustrationSrc from 'assets/team-illustration-2.svg'
import { ReactComponent as SvgDotPattern } from 'assets/dot-pattern.svg'

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 sm:pt-4 sm:pb-16 md:py-24 items-center`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = styled(Column)((props) => [
  tw`flex-shrink-0 relative sm:hidden`,
  props.wideText ? tw`md:w-5/12` : tw`md:w-6/12`,
])

const TextColumn = styled(Column)((props) => [
  tw`mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
  props.wideText ? tw`md:w-7/12` : tw`md:w-6/12`,
])

const MobileImageContainer = tw.div`pt-10 pb-8 2xl:hidden xl:hidden lg:hidden md:hidden`

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
])

const Signature = styled.img((props) => [tw`max-w-72 mt-8`])

const TextContent = tw.div`lg:py-8 text-left lg:text-center md:text-left px-0`

const Subheading = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-light font-serif text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`font-light font-sans mt-4 text-left text-lg leading-relaxed text-blue-500`

export default ({
  subheading = 'Our Expertise',
  heading = (
    <>
      Designed & Developed by <span tw="text-aqua-500">Professionals.</span>
    </>
  ),
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  primaryButtonText = 'Learn More',
  primaryButtonUrl = 'https://timerse.com',
  showButton = true,
  imageSrc = TeamIllustrationSrc,
  signatureSrc = null,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageTitle = null,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
  mode = 'light',
  wideText = false,
  graphMode = false,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container css={[mode == 'dark' && tw`bg-gray-900 -mx-12`]}>
      <TwoColumn>
        <ImageColumn wideText={wideText} css={[mode == 'dark' && tw`px-10`]}>
          {graphMode ? (
            <Chart
              title={imageTitle}
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          ) : (
            <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          )}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft} wideText={wideText}>
          <TextContent css={[mode == 'dark' && tw`px-12 xl:px-0`]}>
            <Subheading>{subheading}</Subheading>
            <Heading css={[mode == 'dark' && tw`text-white`]}>{heading}</Heading>
            <MobileImageContainer>
              {graphMode ? (
                <Chart
                  title={imageTitle}
                  src={imageSrc}
                  imageBorder={imageBorder}
                  imageShadow={imageShadow}
                  imageRounded={imageRounded}
                />
              ) : (
                <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
              )}
            </MobileImageContainer>
            <Description css={[mode == 'dark' && tw`text-gray-200`]}>{description}</Description>
            {showButton && (
              <SecondaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </SecondaryButton>
            )}
            <Signature src={signatureSrc} />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
