// src/components/headers/CountryBar.js

import React, { useContext } from 'react'
import tw from 'twin.macro'
import { LocationContext } from '../../contexts/LocationContext' // Adjust the path as necessary

// Flex container with vertical centering
const BarContainer = tw.div`top-0 left-0 right-0 bg-red-500 text-white px-4 text-center shadow-md z-50 h-12 flex items-center justify-center z-10 relative`
const BarText = tw.p`text-base font-sans font-medium text-2xs md:text-sm`

const CountryBar = () => {
  const { isInUS } = useContext(LocationContext)
  const countryText =
    "This site isn't intended for US persons. No offer or sale of tokens or services will be made in the US or to US persons."

  return (
    <BarContainer>
      <BarText>{countryText}</BarText>
    </BarContainer>
  )
}

export default CountryBar
