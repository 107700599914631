import React from 'react'
import tw, { styled, css } from 'twin.macro'

// Styled components using twin.macro
const Container = tw.section`bg-gray-900 -mx-12 py-6`
const CarouselContainer = tw.div`relative flex items-center overflow-hidden`
const CarouselTrack = styled.div`
  ${tw`flex`};
  white-space: nowrap;
  animation: scroll linear infinite;
  animation-duration: var(--scroll-duration, 100s);

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% / 2));
    }
  }
`

const TitleContainer = tw.div`w-full mb-12`
const CarouselTitle = tw.h2`text-center font-bold font-sans uppercase text-gray-500 text-sm md:text-sm tracking-widest word-spacing-wide`

const Logo = styled.img`
  ${tw`mx-12`}; // mx-2 for horizontal margins
  height: var(--logo-height, 2rem);
  width: auto;
  cursor: pointer; // Indicate that the logo is clickable
  ${({ link }) => !link && tw`cursor-default`}; // change cursor to default if link is not provided
`

// Updated Logos component
const Logos = ({ logos, repeat }) => {
  // Handler for logo click
  const handleLogoClick = (url) => {
    url && window.open(url, '_blank', 'noopener,noreferrer')
  }

  const logoNodes = Array.from({ length: repeat }, () => logos).flat()
  return logoNodes.map((logo, index) => (
    <Logo
      key={index}
      src={logo.src}
      alt={logo.alt}
      css={{ '--logo-height': '3rem' }}
      onClick={() => handleLogoClick(logo.link)}
      link={logo.link} // Pass the link as a prop
    />
  ))
}

// Carousel component
const Carousel = ({ logos, title }) => {
  // A repeat count ensures there are enough logos to create an infinite scroll effect.
  // This needs to be high enough to cover the screen width without any gaps.
  const repeatCount = 5 // Adjust based on your logos' width to avoid gaps

  return (
    <Container>
      <TitleContainer>
        <CarouselTitle>{title}</CarouselTitle>
      </TitleContainer>
      <CarouselContainer>
        <CarouselTrack css={{ '--scroll-duration': `${logos.length * 10}s` }}>
          <Logos logos={logos} repeat={repeatCount} />
        </CarouselTrack>
      </CarouselContainer>
    </Container>
  )
}

export default Carousel
