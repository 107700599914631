import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const CountdownHolder = styled.div`
  ${tw`flex flex-row items-start justify-start mt-2`}
  ${({ containerStyle }) => containerStyle && containerStyle}
`
const CountdownItem = tw.div`flex flex-row items-center justify-center`

// Conditional styling based on the smallText prop
const CountdownSmallValue = styled.p`
  ${tw`text-white font-sans mt-0 mr-1`}
  ${({ smallText }) => (smallText ? tw`text-xs md:text-sm font-bold` : tw`text-sm md:text-lg font-medium`)}
`
const CountdownLabel = styled.p`
  ${tw`font-sans uppercase tracking-widest mr-1 md:mr-2 text-gray-500`}
  ${({ smallText }) => (smallText ? tw`text-xs md:text-sm font-bold` : tw`text-sm md:text-lg  font-medium`)}
`

const CountdownTimer = ({ targetDateSeconds, containerStyle, smallText }) => {
  const calculateTimeLeft = () => {
    const targetDate = new Date(targetDateSeconds?._seconds * 1000)
    const difference = +targetDate - +new Date()

    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const formatTimeSegment = (segment, label) => (
    <CountdownItem>
      <CountdownSmallValue smallText={smallText}>{timeLeft[segment]}</CountdownSmallValue>
      <CountdownLabel smallText={smallText}>{label}</CountdownLabel>
    </CountdownItem>
  )

  return (
    <CountdownHolder containerStyle={containerStyle}>
      {formatTimeSegment('days', 'D')}
      {formatTimeSegment('hours', 'H')}
      {formatTimeSegment('minutes', 'M')}
      {formatTimeSegment('seconds', 'S')}
    </CountdownHolder>
  )
}

export default CountdownTimer
