// InvestorsWidget.js
import tw from 'twin.macro'
import styled, { css } from 'styled-components'

import alliance from 'assets/investorLogos/alliance.svg'

const investors = [{ src: alliance, alt: 'Alliance logo' }]
const InvestorsContainer = styled.div`
  ${tw`flex flex-col items-start justify-start`}
  ${({ containerStyle }) => containerStyle && containerStyle}
`
const InvestorsTitle = tw.p`font-bold font-sans tracking-widest word-spacing-wide uppercase text-turquoise text-sm text-gray-600`
const InvestorsLogos = tw.div`flex flex-row items-center justify-center mt-4`
const InvestorsLogo = styled.img`
  ${tw`h-6 w-auto`}
`

export const InvestorsWidget = ({ containerStyle }) => {
  return (
    <InvestorsContainer containerStyle={containerStyle}>
      <InvestorsTitle>Backed By</InvestorsTitle>
      <InvestorsLogos>
        {investors.map((logo, index) => (
          <InvestorsLogo key={index} src={logo.src} alt={logo.alt} />
        ))}
      </InvestorsLogos>
    </InvestorsContainer>
  )
}
