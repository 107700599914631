// src/components/ModalComponent.js
import React, { useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import ReactModalAdapter from '../../helpers/ReactModalAdapter.js'
import { useModal } from '../../contexts/ModalContext.js'
import RetailLeadCapture from 'components/modalForms/retailLeadCapture.js'
import InstitutionLeadCapture from 'components/modalForms/institutionLeadCapture.js'
import AdvisorLeadCapture from 'components/modalForms/advisorLeadCapture.js'

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50 bg-black bg-opacity-75`}
    backdrop-filter: blur(10px);
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-auto sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-white outline-none`}
    width: fit-content;
    height: fit-content;
    max-height: 90vh; // Prevent the modal from being taller than the viewport height
    overflow-y: auto; // Enable scrolling for the modal content
    padding: 1rem; // Add some padding to ensure the content isn't touching the edges
  }
`

const ModalContainer = styled.div`
  ${tw`bg-white rounded-lg max-w-lg w-full p-6`}
  overflow-y: auto; // Enable scrolling within the modal container
  max-height: 80vh; // Limit the height of the container to ensure it doesn't touch the top/bottom of the screen
`

const ModalOverlay = tw.div`inset-0 bg-white flex justify-center items-center`
const ModalHeader = tw.div`flex justify-between items-center mb-4`
const ModalTitle = tw.h2`font-light font-serif text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-blue-500 leading-tight text-center`
const ModalSubtitle = tw.p`font-sans text-lg text-blue-400 mb-12 text-center`

const stealthData = {
  title: 'Liquid American real estate.',
  subtitle: "Get access to Truly's liquid and highly performant U.S. residential real estate fund. Coming mid-2024.",
}

const retailData = {
  title: 'Start earning 13% now.',
  subtitle: 'Truly makes it easy to earn reliably high returns from U.S. real estate investing.',
}

const advisorData = {
  title: 'Liquid real estate for your clients.',
  subtitle: 'Truly can help you bring liquid, high-performing U.S. residential real estate to your clients.',
}

const institutionData = {
  title: 'HEIs for institutional portfolios. ',
  subtitle: 'Truly can help your institution get large-ticket, liquid exposure to home equity investments.',
}

const ModalShell = () => {
  const { isModalOpen, closeModal, modalData } = useModal()
  const { type, email, userType } = modalData

  // Disable scrolling on the main document body when the modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    // Cleanup function to reset overflow when component unmounts or modal closes
    return () => {
      document.body.style.overflow = ''
    }
  }, [isModalOpen])

  const getModalContent = () => {
    if (type == 'stealth') {
      return stealthData
    } else if (type == 'retail') {
      return retailData
    } else if (type == 'advisor') {
      return advisorData
    } else if (type == 'institution') {
      return institutionData
    }
    return retailData // Default data if 'type' is not matched
  }

  const content = getModalContent()

  return (
    <StyledModal
      closeTimeoutMS={0}
      className="mainHeroModal"
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
    >
      <ModalOverlay>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>{content.title}</ModalTitle>
          </ModalHeader>
          <ModalSubtitle>{content.subtitle}</ModalSubtitle>
          {type == 'stealth' && <RetailLeadCapture userEmail={email} userType={userType} />}
          {type == 'retail' && <RetailLeadCapture userEmail={email} userType={userType} />}
          {type == 'institution' && <InstitutionLeadCapture userEmail={email} userType={userType} />}
          {type == 'advisor' && <AdvisorLeadCapture userEmail={email} userType={userType} />}
        </ModalContainer>
      </ModalOverlay>
    </StyledModal>
  )
}

export default ModalShell
