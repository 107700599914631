import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Amplitude from 'amplitude-js'

import Spinner from '../misc/Spinner'
import Arrow from '../misc/Arrow'
import CountdownTimer from './CountdownTimer'
import { useWallet } from '../../contexts/WalletContext'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../App'
import fortunes from './Fortunes'

import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'

const FortuneDisplay = styled.div`
  ${tw`flex-col max-w-full border-[1px] border-gray-500 rounded-lg text-white font-sans mt-4`}
`

const SpinnerContainer = tw.div`mt-0`
const Top = tw.div`flex flex-col items-center justify-center px-6 py-4 md:px-8 md:py-6`
const Bottom = tw.div`flex flex-col items-center justify-center px-6 py-4 md:px-8 md:py-6 border-t-[1px] border-gray-700`
const Fortune = tw.div`flex flex-col items-center justify-center`
const FortuneText = tw.p`text-base md:text-lg font-sans text-white font-light italic text-center`
const FortuneNumber = tw.p`text-xl font-sans text-white font-light leading-6`
const FortuneActions = tw.div`flex flex-row items-stretch justify-between w-full`
const FortuneStatItem = tw.div`flex flex-col items-start justify-start`
const ReferralCodeDisplayItem = tw.p`text-xl font-light font-sans tracking-widest flex items-center`
const ReferralCodeDisplayImage = tw.button`w-4 h-4 md:w-6 md:h-6 cursor-pointer`
const TwitterIcon = styled(Twitter)`
  ${tw`fill-current text-white transition-all duration-300 hover:text-turquoise`}
`
const VertDivider = styled.div`
  ${tw`w-[1px] bg-gray-200`}
`
const Label = tw.p`text-2xs md:text-xs text-gray-500 font-sans uppercase font-bold tracking-widest`

const FeedbackMessage = styled.span`
  ${({ state }) =>
    state === 'error'
      ? tw`text-red-700 cursor-default`
      : state === 'success'
      ? tw`text-turquoise cursor-default`
      : state === 'verifying'
      ? tw`text-gray-500 cursor-default`
      : tw`text-white`}
  ${tw`text-2xs md:text-xs font-sans uppercase font-semibold relative inline-flex items-center mt-3 md:mt-4`}
`

const getUTCMidnight = () => {
  const now = new Date()
  const utcMidnight = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))
  // Convert to seconds and wrap in an object as expected by CountdownTimer
  return { _seconds: Math.floor(utcMidnight.getTime() / 1000) }
}

const TodaysFortune = ({ fortuneText, twitterData }) => {
  const { userProgress } = useWallet()
  const { walletAddress } = userProgress
  const [isFortuneShareAttempted, setIsFortuneShareAttempted] = useState(false)
  const [fortuneFeedbackMessage, setFortuneFeedbackMessage] = useState(null)
  const [fortuneFeedbackMessageState, setFortuneFeedbackMessageState] = useState('starting')
  const [userFortuneData, setUserFortuneData] = useState({
    fortuneStreak: 0,
    fortuneLastShared: null,
    fortuneLongestStreak: 0,
    hasSharedToday: false,
  })

  useEffect(() => {
    getUserFortuneData()
  }, [userProgress])

  const getUserFortuneData = () => {
    console.log('getUserFortuneData called')
    if (!userProgress) {
      return
    }

    const userFortuneShareTimes = userProgress.fortuneShareTimes || []
    console.log('userFortuneShareTimes:', userFortuneShareTimes)

    // Convert share times from Firestore Timestamps to Date objects set to UTC midnight
    const shareDates = userFortuneShareTimes.map((timestamp) => {
      return new Date(timestamp.seconds * 1000)
    })
    console.log('shareDates:', shareDates)

    // Determine if shared today
    const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))
    const lastShareDate = shareDates.length > 0 ? shareDates[shareDates.length - 1] : null
    const sharedToday = lastShareDate ? lastShareDate.getTime() === today.getTime() : false

    // Calculate current streak
    let currentStreak = 0
    if (sharedToday) {
      currentStreak = 1
      for (let i = shareDates.length - 2; i >= 0; i--) {
        if (shareDates[i + 1].getTime() === shareDates[i].getTime() + 86400000) {
          currentStreak++
        } else {
          break
        }
      }
    } else {
      // If not shared today, check if the streak is already broken
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      if (lastShareDate && lastShareDate.getTime() === yesterday.getTime()) {
        currentStreak = 1
        for (let i = shareDates.length - 2; i >= 0; i--) {
          if (shareDates[i + 1].getTime() === shareDates[i].getTime() + 86400000) {
            currentStreak++
          } else {
            break
          }
        }
      } else {
        currentStreak = 0 // Streak is broken if not shared today and last share wasn't yesterday
      }
    }

    // Calculate longest streak
    let longestStreak = 0
    let tempStreak = shareDates.length > 0 ? 1 : 0
    for (let i = 1; i < shareDates.length; i++) {
      if (shareDates[i].getTime() === shareDates[i - 1].getTime() + 86400000) {
        tempStreak++
      } else {
        longestStreak = Math.max(longestStreak, tempStreak)
        tempStreak = 1
      }
    }
    longestStreak = Math.max(longestStreak, tempStreak) // Check the last streak

    setUserFortuneData({
      fortuneStreak: currentStreak,
      fortuneLongestStreak: longestStreak,
      fortuneSharedToday: sharedToday,
    })
  }

  const handleShareFortuneToTwitter = () => {
    console.log('fortuneText:', fortuneText)

    const tweetText = `"${fortuneText}"\n\n@TrulyHQ`

    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`, '_blank')

    setIsFortuneShareAttempted(true)
    setFortuneFeedbackMessage('Click to verify that you shared fortune.')
    setFortuneFeedbackMessageState('starting')
  }

  const verifyShareFortuneToTwitter = async () => {
    console.log('verifyShareFortuneToTwitter')
    setFortuneFeedbackMessage('Verifying...')
    setFortuneFeedbackMessageState('verifying')

    if (userFortuneData.fortuneSharedToday) {
      setFortuneFeedbackMessage("You already shared today's fortune.")
      setFortuneFeedbackMessageState('error')
      return
    } else if (!userFortuneData.fortuneSharedToday) {
      const checkLatestTweetForString = httpsCallable(functions, 'checkRecentTweetsForString')
      console.log({ username: twitterData?.twitterUsername, searchString: fortuneText })

      try {
        // TEMPORARILY DISABLING THIS UNTIL WE CAN FIGURE OUT WHAT THE FUCK TO DO
        // const result = await checkLatestTweetForString({
        //   username: twitterData.twitterUsername,
        //   searchString: fortuneText,
        // })

        // console.log('RESULT', result)

        // if (result.data.containsString) {

        if (true) {
          // Add 1 to streak because it hasn't been processed yet
          const isBonus = (userFortuneData.fortuneStreak + 1) % 7 === 0
          console.log('isBonus:', isBonus, 'fortuneStreak modulo:', (userFortuneData.fortuneStreak + 1) % 7)

          Amplitude.getInstance().logEvent('shared fortune on twitter verified', {
            url: window.location.href,
            walletAddress: walletAddress,
            twitterUsername: twitterData?.twitterUsername,
            fortune: fortuneText,
            fortuneStreak: userFortuneData.fortuneStreak + 1,
          })

          console.log('Calling newFortuneShare function')
          const newFortuneShare = httpsCallable(functions, 'newFortuneShare')
          newFortuneShare({ walletAddress })
            .then((result) => {
              if (result.data.success) {
                console.log('isBonus:', isBonus, 'fortuneStreak modulo:', (userFortuneData.fortuneStreak + 1) % 7)
                awardPointsForFortuneShare(fortuneText, isBonus)
                getUserFortuneData()
              } else {
                setFortuneFeedbackMessage('Error. Please try again later.')
                setFortuneFeedbackMessageState('error')
              }
            })
            .catch((error) => {
              console.error('Error calling newFortuneShare function:', error)
              setFortuneFeedbackMessage('Error. Please try again later.')
              setFortuneFeedbackMessageState('error')
            })
        } else {
          setFortuneFeedbackMessage('Fortune not found in latest tweet.')
          setFortuneFeedbackMessageState('error')
        }
      } catch (error) {
        console.error('Verification failed:', error)
        if (error.code === 'permission-denied') {
          setFortuneFeedbackMessage('Too many requests. Try again in 15 minutes.')
        } else {
          setFortuneFeedbackMessage(`Error: ${error.message || 'unknown error'}.`)
        }
        setFortuneFeedbackMessageState('error')
      }
    }
  }

  const awardPointsForFortuneShare = async (fortuneText, isBonus) => {
    // Award 100 points for sharing today's fortune. Award 1,000 points if the user has shared for 7 days in a row.
    console.log('isBonus', isBonus)
    const points = isBonus ? 1000 : 100
    const type = isBonus ? 'fortune_share_bonus' : 'fortune_share'
    const pointsData = {
      walletAddress,
      points: points,
      type: type,
      note: fortuneText,
    }
    const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')

    try {
      const result = await createAndUpdatePoints(pointsData)
      setFortuneFeedbackMessage(`${points} points awarded!`)
      setFortuneFeedbackMessageState('success')
    } catch (error) {
      console.error("Failed to award points for sharing today's fortune", error)
    }
  }

  return (
    <FortuneDisplay>
      <Top>
        <Fortune>
          {fortuneText ? (
            <FortuneText>{fortuneText}</FortuneText>
          ) : (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </Fortune>
        <CountdownTimer targetDateSeconds={getUTCMidnight()} containerStyle={tw`w-auto mt-3`} smallText={true} />
      </Top>

      <Bottom>
        <FortuneActions>
          <ReferralCodeDisplayItem
            onClick={() => {
              handleShareFortuneToTwitter()

              Amplitude.getInstance().logEvent('button clicked', {
                buttonName: 'share fortune to twitter',
                url: window.location.href,
                fortuneText: fortuneText,
                walletAddress: walletAddress,
              })
            }}
          >
            <ReferralCodeDisplayImage>
              <TwitterIcon />
            </ReferralCodeDisplayImage>
          </ReferralCodeDisplayItem>

          <VertDivider />

          <FortuneStatItem>
            <Label>Streak</Label>
            <FortuneNumber>{userFortuneData.fortuneStreak}</FortuneNumber>
          </FortuneStatItem>
          <FortuneStatItem>
            <Label>Longest</Label>
            <FortuneNumber>{userFortuneData.fortuneLongestStreak}</FortuneNumber>
          </FortuneStatItem>
          <FortuneStatItem>
            <Label>Til Bonus</Label>
            <FortuneNumber>
              {userFortuneData.fortuneStreak % 7 === 0 && userFortuneData.fortuneStreak !== 0
                ? 7
                : 7 - (userFortuneData.fortuneStreak % 7)}
            </FortuneNumber>
          </FortuneStatItem>
        </FortuneActions>
        {isFortuneShareAttempted && fortuneFeedbackMessage && (
          <FeedbackMessage
            as="button"
            onClick={verifyShareFortuneToTwitter}
            state={fortuneFeedbackMessageState}
            disabled={fortuneFeedbackMessageState == 'verifying' || fortuneFeedbackMessageState == 'error'}
          >
            {isFortuneShareAttempted && fortuneFeedbackMessageState == 'starting' && <Arrow animate={true} />}
            {fortuneFeedbackMessage}
          </FeedbackMessage>
        )}
      </Bottom>
    </FortuneDisplay>
  )
}

export default TodaysFortune
