let firebaseConfig = {}

if (process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyC2Wj1CAgq1lL4HdcBneI7P93FLrGFFnoQ',
    authDomain: 'villcaso-dev.firebaseapp.com',
    projectId: 'villcaso-dev',
    storageBucket: 'villcaso-dev.appspot.com',
    messagingSenderId: '286178315802',
    appId: '1:286178315802:web:23459b5cfe65c31c374ba3',
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyC2ao939Si8_cCTWvRDEpgAjj9HH6s8P3Y',
    authDomain: 'villcaso-prod.firebaseapp.com',
    projectId: 'villcaso-prod',
    storageBucket: 'villcaso-prod.appspot.com',
    messagingSenderId: '349427367773',
    appId: '1:349427367773:web:5e6717b1825a0ff17c72b4',
    measurementId: 'G-E7K9FFW3LW',
  }
}

module.exports = firebaseConfig
