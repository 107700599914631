import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { css } from 'styled-components/macro'
import tw from 'twin.macro'
import { httpsCallable } from 'firebase/functions'
import Amplitude from 'amplitude-js'

import { functions } from '../../App'
import { useWallet } from '../../contexts/WalletContext'
import Arrow from '../misc/Arrow'

import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'

const ReferralCodeDisplay = styled.div`
  ${tw`border-[1px] border-gray-500 p-4 rounded-lg text-white font-sans flex flex-row items-stretch justify-around mt-4`}
`

const ReferralCodeDisplayItem = tw.p`text-xl font-light font-sans tracking-widest flex items-center`
const ReferralCodeDisplayImage = tw.button`w-6 h-6 cursor-pointer`

const TwitterIconStyled = styled(Twitter)`
  ${tw`fill-current text-white transition-all duration-300 hover:text-turquoise`}
`

const CopyIconStyled = styled(Copy)`
  ${tw`transition-all duration-300 hover:text-turquoise`}
`

const VertDivider = styled.div`
  ${tw`w-[1px] bg-gray-200`}
`

const FeedbackMessage = styled.span`
  ${({ state }) =>
    state === 'error'
      ? tw`uppercase text-red-700`
      : state === 'success'
      ? tw`uppercase text-turquoise cursor-default`
      : state === 'starting'
      ? tw`normal-case text-white cursor-default`
      : state === 'verify'
      ? tw`underline text-white`
      : state === 'verifying'
      ? tw`uppercase text-gray-500 cursor-default`
      : tw`text-white`}
  ${tw`text-xs font-sans font-semibold relative inline-flex items-center mt-4 text-center`}
`

const VerificationSpace = styled.div`
  ${tw`text-center`}
`

const ReferralCode = ({ twitterData }) => {
  const { userProgress } = useWallet()
  const { walletAddress, referralCode, isReferralCodeSharedToTwitter } = userProgress
  const [referralCodeSharingMessage, setReferralCodeSharingMessage] = useState(
    isReferralCodeSharedToTwitter ? null : '⭐ Earn a one-time 500 point bonus when you share your code to Twitter.'
  )
  const [referralCodeSharingState, setReferralCodeSharingState] = useState(
    isReferralCodeSharedToTwitter ? null : 'starting'
  )
  const [isTwitterShareAttempted, setIsTwitterShareAttempted] = useState(false)
  const [verificationCompleted, setVerificationCompleted] = useState(isReferralCodeSharedToTwitter)

  const copyReferralUrlToClipboard = () => {
    const url = `https://www.trulybased.com/points?code=${encodeURIComponent(referralCode)}`
    navigator.clipboard
      .writeText(url)
      .then(() => alert('Referral URL copied to clipboard!'))
      .catch((err) => console.error('Could not copy referral URL:', err))

    Amplitude.getInstance().logEvent('button clicked', {
      buttonName: 'copy referral code to clipboard',
      url: window.location.href,
      walletAddress: walletAddress,
      referralCode: referralCode,
    })
  }

  const handleShareReferralCodeToTwitter = () => {
    const tweetText = `I just got whitelisted for permissionless US real estate from @TrulyHQ.%0A%0AJoin the airdrop with my code: ${encodeURIComponent(
      referralCode
    )}%0A%0A${window.location.protocol}//${window.location.host}/points?code=${encodeURIComponent(referralCode)}`
    window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank')
    setIsTwitterShareAttempted(true)
    setReferralCodeSharingState('verify')
    setReferralCodeSharingMessage('Verify Twitter share for 500 points.')

    Amplitude.getInstance().logEvent('button clicked', {
      buttonName: 'share referral code to twitter',
      url: window.location.href,
      walletAddress: walletAddress,
      referralCode: referralCode,
    })
  }

  const verifyShareReferralCodeToTwitter = async () => {
    setReferralCodeSharingMessage('Verifying...')
    setReferralCodeSharingState('verifying')
    const checkLatestTweetForString = httpsCallable(functions, 'checkRecentTweetsForString')

    try {
      // TEMPORARILY DISABLING UNTIL WE FIGURE OUT WTF TO DO
      // const result = await checkLatestTweetForString({
      //   username: twitterData.twitterUsername,
      //   searchString: referralCode,
      // })

      // if (result.data.containsString) {

      if (true) {
        await awardPointsForReferralCodeShare()
        await updateReferralCodeSharedToTwitter(true)
        setReferralCodeSharingMessage('500 points awarded!')
        setReferralCodeSharingState('success')

        Amplitude.getInstance().logEvent('shared referral code on twitter verified', {
          url: window.location.href,
          walletAddress: walletAddress,
          twitterUsername: twitterData?.twitterUsername,
          referralCode: referralCode,
        })
      } else {
        // console.log('Referral code was not found in the latest tweet:', result)
        setReferralCodeSharingMessage('Referral code not found in latest tweet.')
        setReferralCodeSharingState('error')
      }
    } catch (error) {
      console.error('Verification failed:', error)
      if (error.code === 'permission-denied') {
        setReferralCodeSharingMessage('Too many requests. Try again in 15 minutes.')
      } else {
        setReferralCodeSharingMessage(`Error: ${error.message || 'unknown error'}.`)
      }
      setReferralCodeSharingState('error')
    }
  }

  const updateReferralCodeSharedToTwitter = async (status) => {
    const updateReferralStatus = httpsCallable(functions, 'updateisReferralCodeSharedToTwitter')
    try {
      const updateResult = await updateReferralStatus({
        walletAddress,
        isReferralCodeSharedToTwitter: status,
      })
      console.log('Referral status updated:', updateResult.data.message)
    } catch (error) {
      console.error('Failed to update referral status:', error)
      throw error
    }
  }

  const awardPointsForReferralCodeShare = async () => {
    const points = 500
    const pointsData = {
      walletAddress,
      points: points,
      type: 'twitter_referral_code_share',
      note: null,
    }
    const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')

    try {
      const result = await createAndUpdatePoints(pointsData)
    } catch (error) {
      console.error('Failed to award points for sharing referral code on Twitter', error)
    }
  }

  return (
    <>
      <ReferralCodeDisplay>
        <ReferralCodeDisplayItem>{referralCode}</ReferralCodeDisplayItem>
        <VertDivider />
        <ReferralCodeDisplayItem onClick={copyReferralUrlToClipboard}>
          <ReferralCodeDisplayImage>
            <CopyIconStyled />
          </ReferralCodeDisplayImage>
        </ReferralCodeDisplayItem>
        <VertDivider />
        <ReferralCodeDisplayItem onClick={handleShareReferralCodeToTwitter}>
          <ReferralCodeDisplayImage>
            <TwitterIconStyled />
          </ReferralCodeDisplayImage>
        </ReferralCodeDisplayItem>
      </ReferralCodeDisplay>

      {!verificationCompleted && (
        <VerificationSpace>
          <FeedbackMessage
            as="button"
            onClick={verifyShareReferralCodeToTwitter}
            state={referralCodeSharingState}
            disabled={
              referralCodeSharingState == 'starting' ||
              referralCodeSharingState == 'verifying' ||
              referralCodeSharingState == 'success' ||
              referralCodeSharingState == 'error'
            }
          >
            {isTwitterShareAttempted &&
              referralCodeSharingState !== 'verifying' &&
              referralCodeSharingState !== 'error' &&
              referralCodeSharingState !== 'success' && <Arrow animate={true} />}
            {referralCodeSharingMessage}
          </FeedbackMessage>
        </VerificationSpace>
      )}
    </>
  )
}

export default ReferralCode
