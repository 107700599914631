import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import Lottie from 'lottie-react' // Updated import for lottie-react

import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js'
import { SecondaryButton as SecondaryButtonBase } from 'components/misc/Buttons.js'
import StatsIllustrationSrc from 'assets/stats-illustration.svg'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 sm:pt-4`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 md:h-auto relative sm:hidden`
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const MobileImageContainer = tw.div`pt-10 pb-8 2xl:hidden xl:hidden lg:hidden md:hidden`

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Title = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-light text-left text-3xl md:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`font-sans font-light mt-4 text-center md:text-left text-lg lg:text-lg leading-relaxed text-blue-500`

const Statistics = tw.div`flex flex-col items-center md:block text-center md:text-left mt-4`
const Statistic = tw.div`text-center md:text-left md:inline-block md:mr-12 last:mr-0 mt-4 w-1/3 md:w-1/4 align-top`
const Value = tw.div`font-bold font-sans text-lg md:text-xl lg:text-2xl text-secondary-500 tracking-wide`
const Key = tw.div`font-medium font-sans text-turquoise`

const SecondaryButton = tw(SecondaryButtonBase)`mt-8 md:mt-10 text-sm`

export default ({
  title = 'Our Track Record',
  heading = (
    <>
      We have been doing this <wbr /> since <span tw="text-aqua-500">1999.</span>
    </>
  ),
  description,
  buttonText = 'Learn More',
  buttonOnclick = () => {},
  showButton = true,
  src = StatsIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  lottie,
  statistics = [
    { key: 'Clients', value: '2282+' },
    { key: 'Projects', value: '3891+' },
    { key: 'Awards', value: '1000+' },
  ],
  textOnLeft = false,
  mode = 'light',
}) => {
  return (
    <Container css={[mode == 'dark' && tw`bg-gray-900 -mx-12`]}>
      <TwoColumn css={[mode == 'dark' && tw`mx-12`]}>
        <ImageColumn>
          {lottie ? (
            <div style={{ pointerEvents: 'none', height: 'auto', width: 'auto' }}>
              <Lottie animationData={lottie} loop autoplay />
            </div>
          ) : (
            <Image
              src={src}
              alt={title}
              css={[imageRounded && tw`rounded`, imageBorder && tw`border`, imageShadow && tw`shadow`]}
            />
          )}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {title && <Title>{title}</Title>}
            <Heading css={[mode == 'dark' && tw`text-gray-100`]}>{heading}</Heading>
            <MobileImageContainer>
              {lottie ? (
                <Lottie
                  animationData={lottie}
                  loop
                  autoplay
                  style={{ height: 'auto', width: 'auto', pointerEvents: 'none' }}
                />
              ) : (
                <Image
                  src={src}
                  alt={title}
                  css={[imageRounded && tw`rounded`, imageBorder && tw`border`, imageShadow && tw`shadow`]}
                />
              )}
            </MobileImageContainer>
            <Description css={[mode == 'dark' && tw`text-gray-200`]}>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value css={[mode == 'dark' && tw`text-gray-200`]}>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
            {showButton && (
              <SecondaryButton as="a" onClick={buttonOnclick}>
                {buttonText}
              </SecondaryButton>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
