let privyConfig = {}

if (process.env.NODE_ENV === 'development') {
  privyConfig = {
    appId: 'clt57twqq02inlnt2398m74dx',
  }
} else {
  privyConfig = {
    appId: 'clvd0vmho01g3u9glyza7mkp9',
  }
}

module.exports = privyConfig
