import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import Lottie from 'lottie-react' // Adjusted import for lottie-react

import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js'
import { SecondaryButton as SecondaryButtonBase } from 'components/misc/Buttons.js'
import Chart from 'components/misc/Chart.js'
import TeamIllustrationSrc from 'assets/team-illustration-2.svg'
import { ReactComponent as SvgDotPattern } from 'assets/dot-pattern.svg'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 sm:pt-8 sm:pb-20 md:py-16 items-center`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative sm:hidden`
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
])
const Description = tw.p`font-light font-sans mt-4 text-left text-lg md:text-xl leading-relaxed text-blue-500`

const Image = styled.img((props) => [
  tw`m-auto max-h-[40rem]`,
  props.imageRounded && tw`rounded-lg`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
])

const MobileImageContainer = tw.div`pt-10 pb-2 2xl:hidden xl:hidden lg:hidden md:hidden`

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-aqua-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw.p`text-center md:text-left font-bold font-sans tracking-widest word-spacing-wide uppercase text-turquoise text-sm md:text-base mb-6`
const Heading = tw(
  SectionHeading
)`mt-4 font-light font-serif text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const SecondaryButton = tw(SecondaryButtonBase)`mt-12 text-sm`

const Icon = tw.img`rounded-full bg-gray-100 w-12 h-12 mt-8`

const LottieAnimation = React.memo(({ animationData, height, width, loop, autoplay }) => {
  return (
    <div style={{ pointerEvents: 'none' }}>
      <Lottie animationData={animationData} style={{ height: height, width: width }} loop={loop} autoplay={autoplay} />
    </div>
  )
})

export default ({
  title,
  heading = (
    <>
      Designed & Developed by <span tw="text-aqua-500">Professionals.</span>
    </>
  ),
  description,
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageTitle = null,
  textOnLeft = false,
  steps = null,
  showButton = true,
  decoratorBlobCss = null,
  buttonText = 'Learn More',
  buttonOnclick = () => {},
  mode,
  graphMode = false,
  lottie,
  blockImage,
  showIcon = false,
  iconSrc,
  tight,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container css={[mode == 'dark' && tw`bg-gray-900 -mx-12`]}>
      <TwoColumn css={[tight && tw`py-8 xl:py-0`]}>
        <ImageColumn>
          {graphMode ? (
            <Chart
              title={imageTitle}
              lottie={lottie}
              blockImage={blockImage}
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          ) : lottie ? (
            <LottieAnimation animationData={lottie} height="auto" width="auto" loop={true} autoplay={true} />
          ) : (
            <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          )}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {title ? <Subheading>{title}</Subheading> : null}
            <Heading css={[mode == 'dark' && tw`text-gray-100`]}>{heading}</Heading>
            <MobileImageContainer>
              {graphMode ? (
                <Chart
                  title={imageTitle}
                  lottie={lottie}
                  blockImage={blockImage}
                  src={imageSrc}
                  imageBorder={imageBorder}
                  imageShadow={imageShadow}
                  imageRounded={imageRounded}
                />
              ) : (
                <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
              )}
            </MobileImageContainer>
            <Description css={[mode == 'dark' && tw`text-gray-200`]}>{description}</Description>
            {showButton && (
              <SecondaryButton as="a" onClick={buttonOnclick}>
                {buttonText}
              </SecondaryButton>
            )}
            {showIcon && <Icon src={iconSrc} alt="icon" />}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
