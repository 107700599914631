import React, { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { httpsCallable } from 'firebase/functions'
import { doc, onSnapshot } from 'firebase/firestore'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseModule from '@web3-onboard/coinbase'
import walletConnectModule from '@web3-onboard/walletconnect'
import { ethers } from 'ethers'
import { usePrivy } from '@privy-io/react-auth'
import Amplitude from 'amplitude-js'

import { FingerprintContext } from './FingerprintContext'
import { awardReferralCodeUsedPoints } from '../utils/referralUtils'
import Icon from 'assets/logos/iconDark.svg'
import ProcessingModal from '../components/points/ProcessingModal'
import { db, functions } from '../App'

const WalletContext = createContext()

export const useWallet = () => useContext(WalletContext)

export const WalletProvider = ({ children }) => {
  const [wallets, setWallets] = useState([])
  const [walletAddress, setWalletAddress] = useState('')
  const [userProgress, setUserProgress] = useState(null)
  const [referralCode, setReferralCode] = useState('')
  const [searchParams] = useSearchParams()
  const [connectionStatus, setConnectionStatus] = useState(null)
  const [turnstileToken, setTurnstileToken] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const { logout } = usePrivy()
  const fingerprint = useContext(FingerprintContext)

  const wcInitOptions = {
    projectId: 'f40ba95623b816804eeb51385096c2b7',
    requiredChains: [1],
    dappUrl: 'https://trulybased.com',
  }

  const walletConnect = walletConnectModule(wcInitOptions)

  const onboard = useMemo(
    () =>
      Onboard({
        wallets: [
          injectedModule(),
          coinbaseModule({
            appName: 'Villcaso', // I think this is tied to the infuraId
            infuraId: '6c8a8659221141519960d7b3c8637cae',
          }),
          walletConnect,
        ],
        chains: [
          {
            id: '0x1',
            token: 'ETH',
            label: 'Ethereum Mainnet',
            rpcUrl: 'https://mainnet.infura.io/v3/6c8a8659221141519960d7b3c8637cae',
          },
        ],
        appMetadata: {
          name: 'Truly',
          icon: Icon,
          description: 'Truly is a permissionless real estate investment protocol.',
        },
      }),
    []
  )

  // Load wallet address from local storage when the component mounts
  useEffect(() => {
    const savedWalletAddress = localStorage.getItem('walletAddress')
    if (savedWalletAddress) {
      setWalletAddress(savedWalletAddress)
    }
  }, [])

  useEffect(() => {
    const urlReferralCode = searchParams.get('code')
    if (urlReferralCode) {
      setReferralCode(urlReferralCode)
    }
  }, [searchParams])

  useEffect(() => {
    if (!walletAddress) return
    const unsubscribe = onSnapshot(doc(db, 'Users', walletAddress), (doc) => {
      const data = doc.exists() ? doc.data() : null
      setUserProgress(data)
    })
    return unsubscribe
  }, [walletAddress])

  const handleTurnstileVerification = (token) => {
    setTurnstileToken(token)
    setIsModalOpen(false)
    connectWalletWithVerification(token)
  }

  const connectWalletWithVerification = async (turnstileToken) => {
    console.log('Attempting to connect wallet...')

    if (!turnstileToken) {
      console.error('Turnstile verification failed: no token.')
      return
    }

    try {
      const wallets = await onboard.connectWallet()
      if (!wallets.length) {
        console.error('No wallet connected')
        return
      }

      const wallet = wallets[0]
      const mixedCaseConnectedWalletAddress = wallet.accounts[0].address // This might not actually be mixed case. Seems like different web3 providers do different things.
      const connectedWalletAddress = mixedCaseConnectedWalletAddress.toLowerCase()

      Amplitude.getInstance().logEvent('wallet connected', {
        walletAddress: connectedWalletAddress,
        url: window.location.href, // The full URL of the page
      })

      // Initializing ethersProvider after the wallet is successfully connected
      const ethersProvider = new ethers.providers.Web3Provider(wallet.provider)
      const network = await ethersProvider.getNetwork() // Ensure this is after ethersProvider is initialized

      // Prompting user to switch to Ethereum Mainnet if not already connected to it
      if (network.chainId !== 1) {
        try {
          await ethersProvider.send('wallet_switchEthereumChain', [{ chainId: '0x1' }]) // Correctly formatted chainId
        } catch (switchError) {
          if (switchError.code === 4902) {
            // This error code means the network does not exist in MetaMask, so you need to add it
            await ethersProvider.send('wallet_addEthereumChain', [
              {
                chainId: '0x1',
                rpcUrl: 'https://mainnet.infura.io/v3/your_infura_project_id', // replace with your actual Infura Project ID
              },
            ])
          } else {
            // Handle other errors or re-throw the error
            throw switchError
          }
        }
      }

      const signer = ethersProvider.getSigner()
      setConnectionStatus('Connecting')
      const generateChallenge = httpsCallable(functions, 'generateChallenge')
      const challengeResponse = await generateChallenge({ walletAddress: connectedWalletAddress })
      const { message } = challengeResponse.data

      setConnectionStatus('Verifying')
      const signature = await signer.signMessage(message)

      setConnectionStatus('Processing')
      const verifySignature = httpsCallable(functions, 'verifySignature')
      const verifyResponse = await verifySignature({
        walletAddress: connectedWalletAddress,
        signature,
        message,
        turnstileToken,
      })

      if (!verifyResponse.data.isValid) {
        setConnectionStatus(null)
        throw new Error('Signature verification failed.')
      }

      Amplitude.getInstance().logEvent('wallet ownership verified', {
        walletAddress: connectedWalletAddress,
        url: window.location.href, // The full URL of the page
      })

      const createOrUpdateUser = httpsCallable(functions, 'createOrUpdateUser')
      const userResponse = await createOrUpdateUser({ walletAddress: connectedWalletAddress, fingerprint: fingerprint })
      const userProgressDoc = userResponse.data.user // Retrieve and use user data

      Amplitude.getInstance().logEvent('user created', {
        walletAddress: connectedWalletAddress,
        url: window.location.href, // The full URL of the page
      })

      console.log('userProgressDoc:', userProgressDoc)

      console.log('referral code present in ConnectWallet:', referralCode)

      if (referralCode && !userProgressDoc?.referralCodeRedeemedAt) {
        console.log('Processing referral code...')
        const processReferral = httpsCallable(functions, 'processReferral')
        const processReferralResult = await processReferral({
          newUserWalletAddress: connectedWalletAddress,
          referralCode,
        })

        Amplitude.getInstance().logEvent('signed up with referral code in url', {
          walletAddress: walletAddress,
          referralCode: referralCode,
          url: window.location.href, // The full URL of the page
        })

        console.log('Referral code processed:', processReferralResult)
        if (processReferralResult.data.success) {
          const pointsData = {
            walletAddress: connectedWalletAddress,
            points: 50,
            type: 'redeem_referral_code',
            note: '',
          }
          const createAndUpdatePoints = httpsCallable(functions, 'createAndUpdatePoints')
          try {
            // Award points to new user for redeeming referral code
            const result = await createAndUpdatePoints(pointsData)
            console.log('Referral code points successfully awarded:', result)

            // Award points to the referrer
            const awardResult = await awardReferralCodeUsedPoints(functions, connectedWalletAddress, referralCode)
            console.log('Referral points awarded:', awardResult)
          } catch (error) {
            console.error('Failed to award points:', error)
          }
        }
      }

      // Set userProgress
      if (userProgressDoc) {
        setUserProgress(userProgressDoc)
        console.log('User progress updated:', userProgressDoc)
      } else {
        setUserProgress(null)
        console.log('No user progress found.')
      }

      navigate('/points')
      setWalletAddress(connectedWalletAddress)
      localStorage.setItem('walletAddress', connectedWalletAddress)
      setWallets(wallets)
      setConnectionStatus(null)
    } catch (error) {
      console.error('Wallet connection failed:', error)
      setConnectionStatus(null)
    }
  }

  const connectWallet = () => {
    setIsModalOpen(true)
  }

  const disconnectWallet = async () => {
    if (wallets.length) {
      await onboard.disconnectWallet({ label: wallets[0].label })

      Amplitude.getInstance().logEvent('wallet disconnected', {
        walletAddress: walletAddress,
        url: window.location.href, // The full URL of the page
      })
    }

    // Remove wallet address from local storage
    localStorage.removeItem('walletAddress')

    // Log out of Privy
    logout()

    // Reset wallet address and user progress
    setWalletAddress(null)
    setUserProgress(null)
  }

  const contextValue = useMemo(
    () => ({
      walletAddress,
      connectWallet, // Memoized or defined outside and passed in
      disconnectWallet, // Memoized or defined outside and passed in
      userProgress,
      referralCode,
      connectionStatus,
    }),
    [walletAddress, userProgress, referralCode, connectionStatus]
  )

  return (
    <WalletContext.Provider value={contextValue}>
      {children}
      <ProcessingModal
        isOpen={isModalOpen}
        onClose={() => {}}
        text={connectionStatus || 'Processing...'}
        onVerify={handleTurnstileVerification}
        renderTurnstile={true}
      />
    </WalletContext.Provider>
  )
}
